<template>
	<div class="Return">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Gestion des retours</h1>

		<b-modal ref="addReturnModal" hide-footer hide-header size="lg">
			<div class="app-card app-card-notification shadow-sm mb-4" style="margin: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['addReturnModal'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
									<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Ajouter un retour</h4>
						</div>

					</div>

				</div>
				<div class="app-card-body p-4" style="margin-bottom: -2em; margin-top: -2em">
					<div class="notification-content">
						<div class="text-center">
							<p style="font-weight: bold;">Choisir un arbitre:</p>
							<div class="row" style="margin-bottom: 1em">
								<div class="col-md-5 offset-md-1">
									<b-form-select v-model="groupReferee" @change="filterReferee()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
										<b-form-select-option :value="0">Choisir un groupe</b-form-select-option>
										<b-form-select-option v-for="r in listGroupAvailable" :key="r.id" :value="r.id">{{ r.wording }}</b-form-select-option>
									</b-form-select>
								</div>

								<div class="col-md-5">
									<b-form-select v-model="addReturnReferee" @change="showRefereeMatches()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
										<b-form-select-option disabled :value='0'>Choisir un arbitre</b-form-select-option>
										<b-form-select-option disabled :value='0'>--- Arbitre position 1 ---</b-form-select-option>
										<b-form-select-option v-for="r in filterListRefereePos1Available" :key="r.nationalNumber" :value="r.nationalNumber">{{ r.firstName }} {{ r.lastName }}</b-form-select-option>
										<b-form-select-option disabled :value='0'>--- Arbitre position 2 ---</b-form-select-option>
										<b-form-select-option v-for="r in filterListRefereePos2Available" :key="r.nationalNumber" :value="r.nationalNumber">{{ r.firstName }} {{ r.lastName }}</b-form-select-option>
									</b-form-select>
								</div>
							</div>
							<div class="row" v-if="addReturnReferee">
								<hr />
								<p style="font-weight: bold;margin-top: -0.5em">Choisir un match:</p>
								<div>

									<b-form-select class="form-select form-select-sm ml-auto d-inline-flex w-auto" v-model="selectedMatchReturn" name="selectedMatchReturn">
										<b-form-select-option :value='null'>Choisir un match</b-form-select-option>

										<b-form-select-option v-for="assignment in refereeAssignments" :key="assignment.match.date" :value="assignment.historique[0].id">
											
												{{ assignment.match.divisionId }} {{ assignment.match.id }} : {{ assignment.match.date | truncate(10, '')}}
											
										</b-form-select-option>
									</b-form-select>
								</div>
								<div class="" style="margin-top: 1em">
									<b-form-textarea name="motifReturnInput" v-model="motifReturnInput" placeholder="Saisir un motif..." :maxlength="500" rows="3" max-rows="3" style="max-height: 175px"></b-form-textarea>
									<div class="d-flex justify-content-end" style="margin-bottom: 0.25em; padding-top: 0.25em; font-style: oblique; font-size: 12px">
										<span v-text="(500 - motifReturnInput.length)"></span><span style="margin-left: 0.2em">caractère(s) restant(s)</span>
									</div>
								</div>
								<div>
									<b-button v-on:click="addNewReturn()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;">Enregistrer</b-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>
		<!-- Modal -->
		<b-modal ref="modalRetour" hide-footer hide-header size="lg">
			<template #modal-title>
				<div>Retour</div>
			</template>
			<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalRetour'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M9.302 1.256a1.5 1.5 0 0 0-2.604 0l-1.704 2.98a.5.5 0 0 0 .869.497l1.703-2.981a.5.5 0 0 1 .868 0l2.54 4.444-1.256-.337a.5.5 0 1 0-.26.966l2.415.647a.5.5 0 0 0 .613-.353l.647-2.415a.5.5 0 1 0-.966-.259l-.333 1.242-2.532-4.431zM2.973 7.773l-1.255.337a.5.5 0 1 1-.26-.966l2.416-.647a.5.5 0 0 1 .612.353l.647 2.415a.5.5 0 0 1-.966.259l-.333-1.242-2.545 4.454a.5.5 0 0 0 .434.748H5a.5.5 0 0 1 0 1H1.723A1.5 1.5 0 0 1 .421 12.24l2.552-4.467zm10.89 1.463a.5.5 0 1 0-.868.496l1.716 3.004a.5.5 0 0 1-.434.748h-5.57l.647-.646a.5.5 0 1 0-.708-.707l-1.5 1.5a.498.498 0 0 0 0 .707l1.5 1.5a.5.5 0 1 0 .708-.707l-.647-.647h5.57a1.5 1.5 0 0 0 1.302-2.244l-1.716-3.004z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Retour ({{returnSelected.id}})</h4>
						</div>
					</div>
				</div>
			</div>
			<button class="btn app-btn-secondary" style="margin-left: 0.5em; margin-bottom: 1em" v-on:click="showReturnComments(returnSelected.id)" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
				Voir les notes sur le retour
			</button>

			<small v-if="returnSelected.refereeIsReplaced == 0" class="btn app-btn-secondary" style="float: right;border: none !important; margin-left: 0.5em; margin-bottom: 1em">
				<a class="btn app-btn-secondary" v-on:click="assignReturn(returnSelected.id)"> M'affecter le retour</a>
			</small>
			<small v-if="returnSelected.refereeIsReplaced == 2" style="float: right;border: none !important; margin-left: 0.5em; margin-bottom: 1em">
				<span style="font-weight: bold;">Le retour est géré par :</span> {{returnSelected.processingUser.firstName}} {{returnSelected.processingUser.lastName}}
			</small>
			<small v-if="returnSelected.refereeIsReplaced == 1" style="float: right;border: none !important; margin-left: 0.5em; margin-bottom: 1em">
				<span v-if="returnSelected.processingUser.nationalNumber == 0">
					<span style="font-weight: bold;">Le retour a été géré par :</span> {{returnSelected.dispatcher.firstName}} {{returnSelected.dispatcher.lastName}}
				</span>
				<span v-else>
					<span style="font-weight: bold;">Le retour a été géré par :</span> {{returnSelected.processingUser.firstName}} {{returnSelected.processingUser.lastName}}
				</span>
				
			</small>


			<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
				<div class="offcanvas-header" style="border-bottom: solid 1px #fa7507; background-color: rgba(0,0,0,.05) !important;">
					<h5 class="offcanvas-title" id="offcanvasExampleLabel">Notes</h5>
					<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
				</div>
				<div class="offcanvas-body">
					<div>
						<div class="" style="margin-top: 1em">
							<small color="red">Merci de ne pas utiliser d'accent, ni de caractères spéciaux, ni ponctuation</small>
							<b-form-textarea name="returnCommentInput" v-model="returnCommentInput" placeholder="Ajouter un commentaire" :maxlength="500" rows="3" max-rows="3" style="max-height: 175px; margin-top: 0.5em"></b-form-textarea>
							<div class="d-flex justify-content-end" style="margin-bottom: 0.25em; padding-top: 0.25em; font-style: oblique; font-size: 12px">
								<span v-text="(500 - returnCommentInput.length)"></span><span style="margin-left: 0.2em">caractère(s) restant(s)</span>
							</div>
						</div>
						<div style="text-align: left">
							<b-button v-on:click="addNewComment(info)" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 100px; margin-bottom: 1em; margin-top: -1.5em"><small>Ajouter</small></b-button>
						</div>
					</div>
					<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
						<div class="app-card app-card-orders-table shadow-sm mb-5">
							<div class="app-card-body">
								<div class="table-responsive table-striped">
									<table class="table table-striped table-hover mb-0 text-center" style="width: 99%">
										<tbody>
											<tr v-for="item in returnComments.slice().reverse()" :key="item.idComment" class="align-middle">

												<td class="cell">
													<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="deleteComment(item)">
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
															<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
															<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
														</svg>
													</a>
													<div class="row" style="text-align: left;">
														<div class="col-12" style="font-weight: bold">
															<small>
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill text-primary mr-2" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
																	<path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
																</svg>
																{{item.user.firstName}} {{item.user.lastName}}
															</small>
														</div>
														<div class="col-12">
															<small>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z" />
																	<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
																</svg>
																{{item.date}}
															</small>
														</div>
														<hr>
													</div>

													<div class="row">
														<div class="col-12">
															{{ item.comment }}
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion" role="tablist" style="margin-bottom: -1em">
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.match type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<!--<div class="notification-type mb-2">
							<span class="badge bg-info">
								Légende
							</span>
						</div>-->
								<h4 class="notification-title mb-1" id="title-legende">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(47 87 136)" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
									</svg><span style="color: rgb(47 87 136);"> Informations du match</span>
								</h4>
								<ul class="notification-meta list-inline mb-0" style="color:">
									<li class="list-inline-item">
										Information liées à la rencontre
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse visible id="match" accordion="my-accordion" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -0.5em">
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Division :
										</strong>
										{{returnSelected.match.divisionId}} - {{returnSelected.match.id}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Horaire :
										</strong>
										{{returnSelected.match.date}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Poule :
										</strong>
										{{returnSelected.match.poule.nomLong}}
									</li>
								</ul>
								<hr />
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Salle :
										</strong>
										{{returnSelected.match.sportsHall.name}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Adresse :
										</strong>
										{{returnSelected.match.sportsHall.address}}
									</li>
								</ul>
								<hr />
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Equipe A :
										</strong>
										{{returnSelected.match.homeClub.name}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Equipe B :
										</strong>
										{{returnSelected.match.visitorClub.name}}
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</div>
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.return id="title-legende" type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" style="color: rgb(20 57 96);">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(47 87 136)" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
										<path fill-rule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
									</svg>
									<span style="color: rgb(47 87 136);"> Retour</span>
								</h4>
								<ul class="notification-meta list-inline mb-0">
									<li class="list-inline-item">
										Gestion du retour (acceptation, avertissement, amende,...)
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse id="return" accordion="my-accordion" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -0.5em">
								<!--<div v-if="returnSelected.replacementDispatcherId != 0">
							{{ returnSelected.dispatcher.firstName }}
						</div>-->
								<ul class="list-unstyled">
									<li v-if="returnSelected.nationalNumber == returnSelected.referee1.nationalNumber">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong style="color: red">Retour de l'arbitre n°1 : </strong>{{returnSelected.referee1.firstName}} {{returnSelected.referee1.lastName}}
									</li>
									<li v-if="returnSelected.nationalNumber !== returnSelected.referee1.nationalNumber">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>Arbitre n°1 : </strong>{{returnSelected.referee1.firstName}} {{returnSelected.referee1.lastName}}
									</li>
									<li v-if="returnSelected.nationalNumber == returnSelected.referee2.nationalNumber">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong style="color: red">Retour de l'arbitre n°2 : </strong>{{returnSelected.referee2.firstName}} {{returnSelected.referee2.lastName}}
									</li>
									<li v-if="returnSelected.nationalNumber !== returnSelected.referee2.nationalNumber">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>Arbitre n°2 : </strong>{{returnSelected.referee2.firstName}} {{returnSelected.referee2.lastName}}
									</li>
									<hr />
									<li>
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>Commentaire : </strong>{{returnSelected.comment}}
									</li>
									<hr />
									<li style="margin-top: 0.25em">
										<small>
											<div>
												<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
													<path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z" />
													<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
												</svg>
												<strong>Date de création du retour : </strong> {{returnSelected.returnCreationDate}}
											</div>


											<div class="row d-flex justify-content-around" v-if="returnSelected.replacementDate">
												<div class="col-6">
													<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
														<path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
														<path fill-rule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
													</svg>
													<strong>Remplacement de l'arbitre : </strong> {{returnSelected.replacementDate}}
												</div>
												<div class="col-6">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill text-primary mr-2" style="margin: 0.4em">
														<path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
														<path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
													</svg>
													<strong>Répartiteur : </strong>
													<span style="font-style: italic">
														{{ returnSelected.dispatcher.firstName }} {{ returnSelected.dispatcher.lastName }}
													</span>
												</div>
											</div>
											<div v-else>
												<span>
													<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
														<path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
														<path fill-rule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
													</svg>L'arbitre n'a pas encore été remplacé
												</span>
											</div>
											<div style="text-align: center">
												<hr />
												<b-button class="btn app-btn-secondary" style="width: 250px; margin: 0.5em" v-if="returnSelected.file.fileSave && !returnSelected.file.fileDelete" v-on:click="downloadJustificatif(returnSelected.id)">Visualiser le justificatif</b-button>
												<div style="text-align: left !important">
													<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
														<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
														<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
													</svg>
													<small v-if="returnSelected.file.dateCreation">Justificatif ajouté le : {{ returnSelected.file.dateCreation }}</small>
													<small v-if="!returnSelected.file.dateCreation && !returnSelected.file.fileDelete">Aucun justificatif n'a été envoyé</small>
													<small v-if="returnSelected.file.fileDelete">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
															<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
															<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
														</svg>
														Justificatif supprimé le : {{ returnSelected.file.dateDelete }}
													</small>
												</div>

											</div>
											<div class="row" v-if="returnSelected.validationDate">
												<div class="col-6">
													<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
														<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
														<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
													</svg>
													<strong>Statut : </strong>
													<span style="font-style: italic">
														<span v-if="returnSelected.status == 1">Validé</span>
														<span v-if="returnSelected.status == 2">Avertissement</span>
														<span v-if="returnSelected.status == 3">Amende</span>
													</span>
												</div>
												<div class="col-6">
													<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill text-primary mr-2" style="margin: 0.4em">
														<path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
														<path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
													</svg>
													<strong>Validé par : </strong>
													<span style="font-style: italic">
														{{ returnSelected.admin.firstName }} {{ returnSelected.admin.lastName }}
													</span>
												</div>
											</div>
											<div v-else>
												<span>
													<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
														<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
														<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
													</svg>
													Le retour n'a pas encore été validé
													<hr />
													<div class="form-group">
														<label class="form-label" style="font-weight: bold;">
															<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
															</svg>
															Statut du retour
														</label>
														<div class="col-md-6 offset-md-3">
															<select class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="status" required :disabled="!userIsAdmin">
																<option disabled value="12">Choisissez un statut</option>
																<option v-bind:value="0" style="font-style:italic">Non traité</option>
																<option v-bind:value="2">Avertissement</option>
																<option v-bind:value="3">Amende</option>
																<option v-bind:value="1">Validé</option>
															</select>
														</div>

													</div>
												</span>
											</div>

										</small>
									</li>
								</ul>
								<hr />

								<div class="text-center" style="margin-bottom: 0.25em">

									<b-button class="btn app-btn-primary" style="width: 175px; margin: 0.5em" v-if="!returnSelected.validationDate" v-on:click="validatedReturn()">Valider le statut</b-button>
									<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136); color: white; width: 175px; margin: 0.5em" v-if="!returnSelected.validated" v-on:click="deleteReturn()">Supprimer le retour</b-button>
								</div>

							</div>
						</div>
					</b-collapse>
				</div>
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.referee id="title-legende" type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" style="color: rgb(20 57 96);">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(47 87 136)" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
										<path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
									</svg>
									<span style="color: rgb(47 87 136);"> Remplacement de l'arbitre</span>
								</h4>
								<ul class="notification-meta list-inline mb-0">
									<li class="list-inline-item">
										Remplacement de l'arbitre
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse id="referee" accordion="my-accordion" role="tabpanel">
						<div class="app-card-body p-4" v-if="returnSelected.refereeIsReplaced == 0 || returnSelected.refereeIsReplaced == 2">
							<div class="notification-content" style="margin-bottom: -1em; margin-top: -0.5em">

								<div class="accordion5" role="tablist">
									<div class="app-card app-card-notification shadow-sm mb-4">
										<div block v-b-toggle.legende id="title-legende" type="button" data-toggle="" data-target="#legende1" aria-expanded="false" aria-controls="legende1" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid #fa7507">
											<div class="row g-3 align-items-center">
												<div class="col-12 col-lg-auto text-lg-left">
													<h4 class="notification-title mb-1" style="color: #fa7507;">
														<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-index" viewBox="0 0 16 16">
															<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 1 0 1 0V6.435a4.9 4.9 0 0 1 .106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 0 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.035a.5.5 0 0 1-.416-.223l-1.433-2.15a1.5 1.5 0 0 1-.243-.666l-.345-3.105a.5.5 0 0 1 .399-.546L5 8.11V9a.5.5 0 0 0 1 0V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z" />
														</svg> Légende
													</h4>
													<ul class="notification-meta list-inline mb-0">
														<li class="list-inline-item">
															Information concernant l'état de désignation des arbitres dans la liste déroulante
														</li>
													</ul>
												</div>
											</div>
										</div>
										<b-collapse id="legende" accordion="my-accordion5" role="tabpanel">
											<div class="app-card-body p-4">
												<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">
													<ul class="list-unstyled">
														<li>
															🔝 Arbitre région ou championnat de france
														</li>referee1
														<li>
															⛔ Ne peut pas être désigné sur la rencontre
														</li>
														<li>
															⚠ L'arbitre est déjà désigné sur la même semaine
														</li>
														<li>
															🛑 L'arbitre est déjà désigné sur la même journée
														</li>
													</ul>
												</div>
											</div>
										</b-collapse>
									</div>
								</div>

								<div class="col-auto">
									<span>
										Choisir un arbitre pour remplacement
									</span>
									<hr />
								</div>
								<ul class="list-unstyled">
									<li v-if="returnSelected.nationalNumber == returnSelected.referee1.nationalNumber">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong style="color: red">Arbitre à remplacer : </strong>{{returnSelected.referee1.firstName}} {{returnSelected.referee1.lastName}}
									</li>
									<li v-if="returnSelected.nationalNumber == returnSelected.referee2.nationalNumber">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong style="color: red">Arbitre à remplacer : </strong>{{returnSelected.referee2.firstName}} {{returnSelected.referee2.lastName}}
									</li>
									<hr />
								</ul>
								<div class="col-md-12">
									<div class="text-center">
										<strong>Arbitre 1</strong>
									</div>

									<div v-if="returnSelected.nationalNumber == returnSelected.referee2.nationalNumber" style="text-align: center;">
										<div v-if="returnSelected.nouvelleDesignationB">
											{{returnSelected.nouvelleDesignation.arbitre1.firstName}} {{returnSelected.nouvelleDesignation.arbitre1.lastName}}
										</div>
										<div v-else>
											{{returnSelected.referee1.firstName}} {{returnSelected.referee1.lastName}}
										</div>
										<hr />
									</div>
									<div v-else class="text-center align-middle" style="margin-top: 0.5em">
										<div class="row align-middle" style=" padding: 0.5em">
											<div class="col-md-4 col-sm-12" style="background-color: rgba(20,57,96, 0.5) !important; border-right: 2px solid #fa7507; padding: 0.5em;margin-top: 0.5em; border-top-left-radius: 10px; border-bottom-left-radius: 10px; ">
												<div style="font-weight: bold;text-align: center;color: white">Arbitre n°2 :</div>

												<div class="col-md-10 offset-md-1 pr-1" style="margin-top: 0.5em">
													<b-form-select v-model="groupAssignmentReferee1" @change="FiltreArbitreModalDesignation()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
														<b-form-select-option :value="0">Choisir un groupe</b-form-select-option>
														<b-form-select-option v-for="r in listGroupAvailable" :key="r.id" :value="r.id">{{ r.wording }}</b-form-select-option>
													</b-form-select>
												</div>
												<div class="col-md-10 offset-md-1 pl-1" style="margin-top: 0.5em; margin-bottom: 1em">
													<b-form-select v-model="addAssignmentReferee1" @change="GetUnavailabilityReferee1() + GetPreviousAssignmentReferee1() + GetPreviousUnavailabilitiesReferee1()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
														<b-form-select-option :value='0' disabled>Choisir un arbitre</b-form-select-option>
														<b-form-select-option :value='0' disabled>--- Arbitre position 1 ---</b-form-select-option>
														<b-form-select-option v-for="r in filterListReferee1Pos1Available" :key="r.nationalNumber" :value="r.nationalNumber">
															<div v-if="r.distance == 0" style="color: red">
																<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
																	{{ r.lastName }} {{ r.firstName }} (hors limite) ⚠<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
																	{{ r.lastName }} {{ r.firstName }} (hors limite) 🛑<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else>
																	{{ r.lastName }} {{ r.firstName }} (hors limite)<span v-if="r.status == 3"> 🔝</span>
																</div>
															</div>
															<div v-if="r.distance != 0">
																<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) ⚠<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) 🛑<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else>
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km)<span v-if="r.status == 3"> 🔝</span>
																</div>
															</div>
														</b-form-select-option>
														<b-form-select-option :value='0' disabled>--- Arbitre position 2 ---</b-form-select-option>
														<b-form-select-option v-for="r in filterListReferee1Pos2Available" :key="r.nationalNumber" :value="r.nationalNumber">
															<div v-if="r.distance == 0" style="color: red">
																<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
																	{{ r.lastName }} {{ r.firstName }} (hors limite) ⚠<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
																	{{ r.lastName }} {{ r.firstName }} (hors limite) 🛑<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else>
																	{{ r.lastName }} {{ r.firstName }} (hors limite)<span v-if="r.status == 3"> 🔝</span>
																</div>
															</div>
															<div v-if="r.distance != 0">
																<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) ⚠<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) 🛑<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else>
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km)<span v-if="r.status == 3"> 🔝</span>
																</div>
															</div>
														</b-form-select-option>
													</b-form-select>
												</div>

											</div>

											<!-- Informations dispo et historique ref2-->
											<div class="col-md-8 col-sm-12" style="background-color: rgb(207, 207, 207); padding: 0.5em; margin-top: 0.5em; border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
												<div v-if="!referee1.nationalNumber" class="app-card app-card-account d-flex flex-column align-items-start col-md-12" style="background-color: rgb(207, 207, 207); border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
													<div class="app-card-body px-4 w-100">
														<div class="item py-3">
															<div class="row justify-content-center align-items-center">
																<div class="col-auto">
																	<p>Sélectionnez un arbitre</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-if="referee1.nationalNumber" class="app-card app-card-account d-flex flex-column align-items-start col-md-12" style="background-color: rgb(207, 207, 207); border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">

													<div class="app-card-body px-4 w-100">
														<div class="row justify-content-center align-items-center" style="text-align: center">
															<div class="col-auto" style="text-align: center">
																<h5 style="color: rgb(20 57 96) !important;">{{referee1.firstName}} {{referee1.lastName}}</h5>
															</div>
														</div>
														<div class="item py-3">

															<div class="row justify-content-center align-items-center">
																<div class="col-auto">
																	<strong>Groupe : </strong>{{referee1.refereeLevel.id}}
																</div>
																<div class="col-auto">
																	<strong>Position : </strong>{{referee1.refereePosition}}
																</div>
																<div v-if="referee1.canDouble" class="col-auto">
																	<strong>Peut doubler :</strong>
																	Oui
																</div>
																<div v-else class="col-auto">
																	<strong>Peut doubler :</strong>
																	Non
																</div>
																<div v-if="referee1.tutor" class="col-auto">
																	<strong style="color:darkgreen">Tuteur</strong>
																</div>
															</div>
															<div class="row justify-content-center align-items-center" style="margin-bottom: 0.5em">
																<div class="col-auto">
																	<strong>Club</strong> : <span>{{ referee1.club.name }}</span>
																</div>
															</div>
															<div class="row justify-content-center align-items-center" style="margin-bottom: 0.5em">
																<div v-if="referee1.colleagueMandatory.nationalNumber != 0" class="col-auto">
																	<strong>Doit être désigné avec</strong> : <span style="color: #fa7507">{{ referee1.colleagueMandatory.firstName }} {{ referee1.colleagueMandatory.lastName }}</span>
																</div>
															</div>
															<div class="row justify-content-around align-items-center">
																<div v-if="referee1.phone" class="col-auto">
																	<strong>N° de téléphone :</strong>
																	{{referee1.phone}}
																</div>
																<div v-else class="col-auto">
																	<strong>N'a pas de numéro de téléphone enregistré</strong>
																</div>
																<div v-if="referee2.city" class="col-auto">
																	<strong>Ville :</strong>
																	{{referee1.city}}
																</div>
																<div v-else class="col-auto">
																	<strong>Aucune adresse enregistrée</strong>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<!---->

										<div v-if="referee1.nationalNumber" style="margin-top: 1em">
											<!--Indisponibilités de la semaine-->
											<div v-if="previousUnavailabilitiesReferee1" class="app-card app-card-orders-table shadow-sm" style="border: 1px solid #fa7507; padding: 0.5em; border-radius: 10px; margin-bottom: 0.5em;">
												<div class="row">
													<div class="col-md-3 col-sm-12" style="text-align: center;">
														<strong>
															Indisponibilité(s) sur 7 jours
														</strong>
													</div>
													<div class="col-md-9 col-sm-12">
														<div class="app-card-body">
															<table class="table table-striped table-hover mb-0 text-left-center" style="text-align: center;">
																<thead>
																	<tr>
																		<th class="mb-2 text-primary">Date de début</th>
																		<th class="mb-2 text-primary">Date de fin</th>
																	</tr>
																</thead>
																<tbody v-if="!noResult">
																	<tr v-for="item in previousUnavailabilitiesReferee1" :key="item.date" class="align-middle">
																		<td class="cell">{{ item.unavailability.start }}</td>
																		<td class="cell">{{ item.unavailability.end }}</td>
																	</tr>
																</tbody>
																<tbody v-if="noResult">
																	<tr>
																		<td colspan="2">Aucun résultat</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>

												</div>

											</div>

											<span v-if="refereeDoubleInWeek1  && refereeDoubleInDay1" class="badge bg-dark" style="margin: 0.5em"><strong style="color: orangered">Attention :</strong> L'arbitre sélectionné(e) est déjà déssigné(e) le même jour que le match sélectionné</span>
											<span v-if="!refereeDoubleInDay1 && refereeDoubleInWeek1" class="badge bg-dark" style="margin: 0.5em"><strong style="color: orangered">Attention :</strong> L'arbitre sélectionné(e) est déjà déssigné(e) le même week-end que le match sélectionné</span>


											<!--Désignation du mois précédent-->
											<div v-if="previousAssignmentsReferee1" class="app-card app-card-orders-table shadow-sm" style="margin-top: 0.5em; border: 1px solid #fa7507; padding: 0.5em; border-radius: 10px;">
												<div class="row">
													<div class="col-md-3 col-sm-12" style="text-align: center;">
														<strong>
															6 dernières désignations
														</strong>
														<br />
														<small>
															au cours des 30 derniers jours
														</small>
													</div>
													<div class="col-md-9 col-sm-12">
														<div class="app-card-body">
															<div class="app-card-body">
																<table class="table table-striped table-hover mb-0 text-left-center">
																	<thead>
																		<tr>
																			<th class="mb-2 text-primary">Horaire</th>
																			<th class="mb-2 text-primary">Division</th>
																			<th class="mb-2 text-primary">Ville</th>
																			<th class="mb-2 text-primary">Locaux</th>
																			<th class="mb-2 text-primary">Visiteur</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr v-for="item in previousAssignmentsReferee1.slice().reverse()" :key="item.date" class="align-middle">
																			<td class="cell">{{ item.date }}</td>
																			<td class="cell">{{ item.divisionId }}</td>
																			<td class="cell">{{ item.sportsHall.city }}</td>
																			<td class="cell">{{ item.homeClub.name }}</td>
																			<td class="cell">{{ item.visitorClub.name }}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>

													</div>
													<!--Fin des désignations du mois précédent-->
												</div>
											</div>
										</div>
									</div>

									<div class="text-center">
										<strong>Arbitre 2</strong>
									</div>
									<div v-if="returnSelected.nationalNumber == returnSelected.referee1.nationalNumber" style="text-align: center;">
										<div v-if="returnSelected.nouvelleDesignationB">
											{{returnSelected.nouvelleDesignation.arbitre2.firstName}} {{returnSelected.nouvelleDesignation.arbitre2.lastName}}
										</div>
										<div v-else>
											{{returnSelected.referee2.firstName}} {{returnSelected.referee2.lastName}}
										</div>
										<hr />
									</div>
									<div v-else class="text-center align-middle" style="margin-top: 0.5em">
										<div class="row align-middle" style=" padding: 0.5em">
											<div class="col-md-4 col-sm-12" style="background-color: rgba(20,57,96, 0.5) !important; border-right: 2px solid #fa7507; padding: 0.5em;margin-top: 0.5em; border-top-left-radius: 10px; border-bottom-left-radius: 10px; ">
												<div style="font-weight: bold;text-align: center;color: white">Arbitre n°2 :</div>

												<div class="col-md-10 offset-md-1 pr-1" style="margin-top: 0.5em">
													<b-form-select v-model="groupAssignmentReferee1" @change="FiltreArbitreModalDesignation()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
														<b-form-select-option :value="0">Choisir un groupe</b-form-select-option>
														<b-form-select-option v-for="r in listGroupAvailable" :key="r.id" :value="r.id">{{ r.wording }}</b-form-select-option>
													</b-form-select>
												</div>
												<div class="col-md-10 offset-md-1 pl-1" style="margin-top: 0.5em; margin-bottom: 1em">
													<b-form-select v-model="addAssignmentReferee2" @change="GetUnavailabilityReferee2() + GetPreviousAssignmentReferee2() + GetPreviousUnavailabilitiesReferee2()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
														<b-form-select-option :value='0' disabled>Choisir un arbitre</b-form-select-option>
														<b-form-select-option :value='0' disabled>--- Arbitre position 1 ---</b-form-select-option>
														<b-form-select-option v-for="r in filterListReferee2Pos1Available" :key="r.nationalNumber" :value="r.nationalNumber">
															<div v-if="r.distance == 0" style="color: red">
																<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
																	{{ r.lastName }} {{ r.firstName }} (hors limite) ⚠<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
																	{{ r.lastName }} {{ r.firstName }} (hors limite) 🛑<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else>
																	{{ r.lastName }} {{ r.firstName }} (hors limite)<span v-if="r.status == 3"> 🔝</span>
																</div>
															</div>
															<div v-if="r.distance != 0">
																<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) ⚠<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) 🛑<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else>
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km)<span v-if="r.status == 3"> 🔝</span>
																</div>
															</div>
														</b-form-select-option>
														<b-form-select-option :value='0' disabled>--- Arbitre position 2 ---</b-form-select-option>
														<b-form-select-option v-for="r in filterListReferee2Pos2Available" :key="r.nationalNumber" :value="r.nationalNumber">
															<div v-if="r.distance == 0" style="color: red">
																<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
																	{{ r.lastName }} {{ r.firstName }} (hors limite) ⚠<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
																	{{ r.lastName }} {{ r.firstName }} (hors limite) 🛑<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else>
																	{{ r.lastName }} {{ r.firstName }} (hors limite)<span v-if="r.status == 3"> 🔝</span>
																</div>
															</div>
															<div v-if="r.distance != 0">
																<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) ⚠<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) 🛑<span v-if="r.status == 3"> 🔝</span>
																</div>
																<div v-else>
																	{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km)<span v-if="r.status == 3"> 🔝</span>
																</div>
															</div>
														</b-form-select-option>
													</b-form-select>
												</div>

											</div>

											<!-- Informations dispo et historique ref2-->
											<div class="col-md-8 col-sm-12" style="background-color: rgb(207, 207, 207); padding: 0.5em; margin-top: 0.5em; border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
												<div v-if="!referee2.nationalNumber" class="app-card app-card-account d-flex flex-column align-items-start col-md-12" style="background-color: rgb(207, 207, 207); border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
													<div class="app-card-body px-4 w-100">
														<div class="item py-3">
															<div class="row justify-content-center align-items-center">
																<div class="col-auto">
																	<p>Sélectionnez un arbitre</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-if="referee2.nationalNumber" class="app-card app-card-account d-flex flex-column align-items-start col-md-12" style="background-color: rgb(207, 207, 207); border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">

													<div class="app-card-body px-4 w-100">
														<div class="row justify-content-center align-items-center" style="text-align: center">
															<div class="col-auto" style="text-align: center">
																<h5 style="color: rgb(20 57 96) !important;">{{referee2.firstName}} {{referee2.lastName}}</h5>
															</div>
														</div>
														<div class="item py-3">

															<div class="row justify-content-center align-items-center">
																<div class="col-auto">
																	<strong>Groupe : </strong>{{referee2.refereeLevel.id}}
																</div>
																<div class="col-auto">
																	<strong>Position : </strong>{{referee2.refereePosition}}
																</div>
																<div v-if="referee2.canDouble" class="col-auto">
																	<strong>Peut doubler :</strong>
																	Oui
																</div>
																<div v-else class="col-auto">
																	<strong>Peut doubler :</strong>
																	Non
																</div>
																<div v-if="referee2.tutor" class="col-auto">
																	<strong style="color:darkgreen">Tuteur</strong>
																</div>
															</div>
															<div class="row justify-content-center align-items-center" style="margin-bottom: 0.5em">
																<div class="col-auto">
																	<strong>Club</strong> : <span>{{ referee2.club.name }}</span>
																</div>
															</div>
															<div class="row justify-content-center align-items-center" style="margin-bottom: 0.5em">
																<div v-if="referee2.colleagueMandatory.nationalNumber != 0" class="col-auto">
																	<strong>Doit être désigné avec</strong> : <span style="color: #fa7507">{{ referee2.colleagueMandatory.firstName }} {{ referee2.colleagueMandatory.lastName }}</span>
																</div>
															</div>
															<div class="row justify-content-around align-items-center">
																<div v-if="referee2.phone" class="col-auto">
																	<strong>N° de téléphone :</strong>
																	{{referee2.phone}}
																</div>
																<div v-else class="col-auto">
																	<strong>N'a pas de numéro de téléphone enregistré</strong>
																</div>
																<div v-if="referee2.city" class="col-auto">
																	<strong>Ville :</strong>
																	{{referee2.city}}
																</div>
																<div v-else class="col-auto">
																	<strong>Aucune adresse enregistrée</strong>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<!---->

										<div v-if="referee2.nationalNumber" style="margin-top: 1em">
											<!--Indisponibilités de la semaine-->
											<div v-if="previousUnavailabilitiesReferee2" class="app-card app-card-orders-table shadow-sm" style="border: 1px solid #fa7507; padding: 0.5em; border-radius: 10px; margin-bottom: 0.5em;">
												<div class="row">
													<div class="col-md-3 col-sm-12" style="text-align: center;">
														<strong>
															Indisponibilité(s) sur 7 jours
														</strong>
													</div>
													<div class="col-md-9 col-sm-12">
														<div class="app-card-body">
															<table class="table table-striped table-hover mb-0 text-left-center" style="text-align: center;">
																<thead>
																	<tr>
																		<th class="mb-2 text-primary">Date de début</th>
																		<th class="mb-2 text-primary">Date de fin</th>
																	</tr>
																</thead>
																<tbody v-if="!noResult">
																	<tr v-for="item in previousUnavailabilitiesReferee2" :key="item.date" class="align-middle">
																		<td class="cell">{{ item.unavailability.start }}</td>
																		<td class="cell">{{ item.unavailability.end }}</td>
																	</tr>
																</tbody>
																<tbody v-if="noResult">
																	<tr>
																		<td colspan="2">Aucun résultat</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>

												</div>

											</div>

											<span v-if="refereeDoubleInWeek2  && refereeDoubleInDay2" class="badge bg-dark" style="margin: 0.5em"><strong style="color: orangered">Attention :</strong> L'arbitre sélectionné(e) est déjà déssigné(e) le même jour que le match sélectionné</span>
											<span v-if="!refereeDoubleInDay2 && refereeDoubleInWeek2" class="badge bg-dark" style="margin: 0.5em"><strong style="color: orangered">Attention :</strong> L'arbitre sélectionné(e) est déjà déssigné(e) le même week-end que le match sélectionné</span>


											<!--Désignation du mois précédent-->
											<div v-if="previousAssignmentsReferee2" class="app-card app-card-orders-table shadow-sm" style="margin-top: 0.5em; border: 1px solid #fa7507; padding: 0.5em; border-radius: 10px;">
												<div class="row">
													<div class="col-md-3 col-sm-12" style="text-align: center;">
														<strong>
															6 dernières désignations
														</strong>
														<br />
														<small>
															au cours des 30 derniers jours
														</small>
													</div>
													<div class="col-md-9 col-sm-12">
														<div class="app-card-body">
															<div class="app-card-body">
																<table class="table table-striped table-hover mb-0 text-left-center">
																	<thead>
																		<tr>
																			<th class="mb-2 text-primary">Horaire</th>
																			<th class="mb-2 text-primary">Division</th>
																			<th class="mb-2 text-primary">Ville</th>
																			<th class="mb-2 text-primary">Locaux</th>
																			<th class="mb-2 text-primary">Visiteur</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr v-for="item in previousAssignmentsReferee2.slice().reverse()" :key="item.date" class="align-middle">
																			<td class="cell">{{ item.date }}</td>
																			<td class="cell">{{ item.divisionId }}</td>
																			<td class="cell">{{ item.sportsHall.city }}</td>
																			<td class="cell">{{ item.homeClub.name }}</td>
																			<td class="cell">{{ item.visitorClub.name }}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>

													</div>
													<!--Fin des désignations du mois précédent-->
												</div>
											</div>
										</div>
									</div>
									<div class="text-center">
										<div class="form-check form-switch mb-3">
											<input class="form-check-input" type="checkbox" v-model="voirArbitreDejaDesigne" id="voirArbitreDejaDesigne"  name="showAllReferee" v-on:click="FiltreArbitreModalDesignation()" style="margin: 0.4em" />
											<label for="allDay">Afficher tous les arbitres</label>
										</div>
										<div class="form-check form-switch mb-3">
											<input class="form-check-input" type="checkbox" v-model="voirArbitreRegional" id="voirArbitreRegional" name="showRefereeSuperior" v-on:click="FiltreArbitreModalDesignation()" style="margin: 0.4em" />
											<label for="allDay1">Afficher les arbitres régionaux et CF</label>
										</div>
										<div class="form-check form-switch mb-3">
											<input class="form-check-input" type="checkbox" v-model="forceDesignation" id="forceDesignation" name="showRefereeSuperior" v-on:click="FiltreArbitreModalDesignation()" style="margin: 0.4em" />
											<label for="allDay1">Forcer l'enregistrement</label>
										</div>
										<hr>
										<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;" v-on:click="addAssignment()">Valider le remplacement</b-button>
									</div>
								</div>
							</div>
						</div>
						<div class="app-card-body p-4" v-if="returnSelected.refereeIsReplaced == 1">
							<div class="notification-content" style="margin-bottom: -1em; margin-top: -0.5em">
								<div class="col-auto">
									L'arbitre à déjà été remplacé par
									<span v-if="returnSelected.referee1.nationalNumber == returnSelected.nationalNumber"> {{ returnSelected.nouvelleDesignation.arbitre1.firstName }} {{ returnSelected.nouvelleDesignation.arbitre1.lastName }}</span>
									<span v-else-if="returnSelected.referee2.nationalNumber == returnSelected.nationalNumber"> {{ returnSelected.nouvelleDesignation.arbitre2.firstName }} {{ returnSelected.nouvelleDesignation.arbitre2.lastName }}</span>
								</div>
							</div>
						</div>
					</b-collapse>
				</div>
			</div>
		</b-modal>


		<b-alert :show="alertSuccessCountDown" variant="success" @dismissed="alertSuccessCountDown=0" @dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success" :max="dismissSecs" :value="alertSuccessCountDown" height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown" variant="warning" @dismissed="alertWarningCountDown=0" @dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning" :max="dismissSecs" :value="alertWarningCountDown" height="4px"></b-progress>
		</b-alert>

		<div class="accordion2" role="tablist">
			<div class="app-card shadow-sm mb-4 border-left-decoration">
				<div class="inner">
					<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<h5 class="title align-middle" style="color: rgb(47 87 136)">
							<i class="fas fa-search-plus"></i>
							Actions
						</h5>
					</div>
					<b-collapse visible id="actions" accordion="my-accordion2" role="tabpanel" class="app-card-body p-4">
						<div class="row g-2 justify-content-center justify-content-md-end">
							<div class="col-12 col-sm-12 col-lg-10" style="margin-top: -1em; margin-bottom: -1em">
								<div class="col-lg-12">
									<div class="row" style="margin-bottom: 1em">
										<div class="col-lg-2 col-md-3 col-sm-12 col-12">
											<label for="dateStartInput" style="font-weight: bold; margin-top: 0.5em"><small>Date de début</small></label>
											<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateStartInputState" name="dateStartInput" v-model="dateStartInput" type="date" required></b-form-input>
										</div>

										<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.5em">
											<label for="dateEndInput" style="font-weight: bold;"><small>Date de fin</small></label>
											<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateEndInputState" name="dateEndInput" v-model="dateEndInput" type="date" required></b-form-input>
										</div>
										<div class="row align-items-center">
											<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="divisionChoice" style="font-weight: bold;"><small>Divisions</small></label>
												</div>

												<b-form-select v-model="divisionChoice" name="divisionChoice" class="form-select form-select-sm ml-auto d-inline-flex text-center">
													<b-form-select-option value="">Choisissez une division</b-form-select-option>
													<b-form-select-option v-for="r in listInfoDivision" :key="r.name" :value="r.id">{{ r.id }}</b-form-select-option>
												</b-form-select>
											</div>
											<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="groupChoice" style="font-weight: bold;"><small>Groupe</small></label>
												</div>

												<b-form-select v-model="groupChoice" name="divisionChoice" class="form-select form-select-sm ml-auto d-inline-flex text-center">
													<b-form-select-option value="">Choisissez un groupe</b-form-select-option>
													<b-form-select-option v-for="r in listGroup" :key="r.wording" :value="r.id">{{ r.wording }}</b-form-select-option>
												</b-form-select>
											</div>
											<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="refereeChoice" style="font-weight: bold;"><small>Arbitre</small></label>
												</div>

												<v-select :options="listReferee" class="form-select-sm ml-auto d-inline-flex text-center" :filter-by="myFilter" label="firstName" style="padding: 0" placeholder="Choissisez une division" v-model="refereeChoice">
													<template v-slot:option="option">
														{{ option.firstName }} {{ option.lastName }}
													</template>
													<template v-slot:no-options="{ search, searching }">
														<template v-if="searching">
															Pas de résultats pour
															<em>{{ search }}</em>.
														</template>
														<em v-else style="opacity: 0.5">Ecrivez pour rechercher un arbitre</em>
													</template>
													<template slot="selected-option" slot-scope="option">
														{{ option.firstName }} {{ option.lastName }}
													</template>
												</v-select>
											</div>
											<div class="col-xs-12 col-sm-12 col-md-12s col-lg-6" style="margin-top: 0.75em">
												<div class="row " style="text-align: left;">
													<div class="col-12 col-md-12 d-flex justify-content-evenly" style="text-align: center;">
														<b-button class="btn app-btn-primary" style="width: 175px; margin-top: 1em;" v-on:click="showReturnInDateSelected()">Afficher les retours</b-button>
														<b-button class="btn app-btn-secondary" style="width: 175px; margin-top: 1em;" v-on:click="showAddReturnModal()">Ajouter un retour</b-button>
													</div>
												</div>
											</div>

										</div>

									</div>
								</div>
							</div>
							<div class="col-12 col-lg-2 align-center align-items-middle" style="text-align: center;">
								<a class="btn app-btn-secondary" style="color: #fa7507; border: 1px solid #fa7507; border-radius: .25rem;" v-on:click="downloadCSV()">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
										<path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
									</svg>
									Extraction CSV
								</a>
							</div><!--//col-->
						</div><!--//row-->
					</b-collapse><!--//app-card-body-->
				</div><!--//inner-->
			</div>
		</div>

		<div v-if="returnVisible && hidden == false" class="tab-content" id="orders-table-tab-content">
			<form class="app-search-form">
				<input name="search" type="text" id="myInput" @keyup="myFunction()" placeholder="Recherchez un nom, un prénom, une date" title="Recherche textuelle" class="form-control search-input" style="height: 50px; margin-bottom: 1em">
				<button disabled class="btn search-btn btn-primary" value="Search" style="padding-top: 1em">
					<svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
						<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
					</svg><!-- <i class="fas fa-search"></i> -->
				</button>
			</form>
			<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
				<div class="app-card app-card-orders-table shadow-sm mb-5">
					<div class="app-card-body">
						<div class="table-responsive table-striped">
							<table id="myTable" class="table table-striped table-hover mb-0 text-center">
								<thead>
									<tr>
										<th class="mb-2 text-primary" v-on:click="sortTable(0)">
											Date de création
											<span v-if="etatTriColumn == 1 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 1 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(1)">
											Division
											<span v-if="etatTriColumn == 2 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 2 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(2)">
											N° de rencontre
											<span v-if="etatTriColumn == 3 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 3 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(3)">
											Heure du match
											<span v-if="etatTriColumn == 4 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 4 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary">Ville</th>
										<th class="mb-2 text-primary">Equipes</th>
										<th class="mb-2 text-primary">
											Arbitre faisant le retour
										</th>
										<th class="mb-2 text-primary">Commentaire</th>

										<th class="mb-2 text-primary" v-on:click="sortTable(6)">
											Statut
											<span v-if="etatTriColumn == 10 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 10 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(7)">
											Remplacé ?
											<span v-if="etatTriColumn == 11 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 11 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary">Arbitre remplacant</th>
										<th class="mb-2 text-primary">Action</th>
									</tr>
								</thead>
								<tbody v-if="!noResult">
									<tr v-for="(item,index) in returns" :key="index" :href="item.id">
										<td class="cell">{{ item.returnCreationDate }}</td>
										<td class="cell">{{ item.match.divisionId }}</td>
										<td class="cell">{{ item.match.id }}</td>
										<td class="cell">{{ item.match.date }}</td>
										<td class="cell">{{ item.match.sportsHall.city }}</td>
										<td class="cell">
											<table class="table table-stiped" style="border: solid 1px orange; text-align: left">
												<tr style="border-bottom: solid 1px orange;">
													<td style="border-right: solid 1px orange;">
														<strong>A</strong>
													</td>
													<td style="padding: 0.25em">
														{{ item.match.homeClub.name}}
													</td>
												</tr>
												<tr>
													<td style="border-right: solid 1px orange;">
														<strong>B</strong>
													</td>
													<td style="padding: 0.25em">
														{{ item.match.visitorClub.name}}
													</td>
												</tr>
											</table>
										</td>
										<td class="cell">
											<table class="table table-stiped" style="border: solid 1px orange; text-align: left">
												<tr style="border-bottom: solid 1px orange;">
													<td style="border-right: solid 1px orange;">
														A1
													</td>
													<td style="padding: 0.25em; color: red" v-if="item.referee1.nationalNumber == item.nationalNumber">
														<strong>{{ item.referee1.firstName }} {{ item.referee1.lastName }}</strong>
													</td>
													<td style="padding: 0.25em" v-else>
														{{ item.referee1.firstName }} {{ item.referee1.lastName }}
													</td>
												</tr>
												<tr>
													<td style="border-right: solid 1px orange;">
														A2
													</td>
													<td style="padding: 0.25em; color: red" v-if="item.referee2.nationalNumber == item.nationalNumber">
														<strong>{{ item.referee2.firstName }} {{ item.referee2.lastName }}</strong>
													</td>
													<td style="padding: 0.25em" v-else>
														{{ item.referee2.firstName }} {{ item.referee2.lastName }}
													</td>
												</tr>
											</table>
										</td>
										<!--<td class="cell" v-if="item.referee1.nationalNumber == item.nationalNumber">{{ item.referee1.firstName }} {{ item.referee1.lastName }}</td>
										<td class="cell" v-if="item.referee2.nationalNumber == item.nationalNumber">{{ item.referee2.firstName }} {{ item.referee2.lastName }}</td>-->
										<td class="cell">{{ item.comment }}</td>
										<td class="cell" v-if="item.status == 0">Non traité</td>
										<td class="cell" v-if="item.status == 1">Validé</td>
										<td class="cell" v-if="item.status == 2">Avertissement</td>
										<td class="cell" v-if="item.status == 3">Amende</td>
										<td class="cell" v-if="item.refereeIsReplaced == 0" style="font-weight: bold; color: red;">Non</td>
										<td class="cell" v-if="item.refereeIsReplaced == 2" style="font-weight: bold; color: #fa7507;">En cours</td>
										<td class="cell" v-if="item.refereeIsReplaced == 1" style="font-weight: bold; color :darkgreen">Oui</td>
										<td class="cell" v-if="item.referee1.nationalNumber == item.nationalNumber && item.refereeIsReplaced == 1">{{ item.arbitreRemplacant.firstName}} {{ item.arbitreRemplacant.lastName }}</td>
										<td class="cell" v-if="item.referee1.nationalNumber == item.nationalNumber && item.refereeIsReplaced != 1"><small style="font-style:italic">Pas encore remplacé</small></td>
										<td class="cell" v-if="item.referee2.nationalNumber == item.nationalNumber && item.refereeIsReplaced == 1">{{ item.arbitreRemplacant.firstName}} {{ item.arbitreRemplacant.lastName }}</td>
										<td class="cell" v-if="item.referee2.nationalNumber == item.nationalNumber && item.refereeIsReplaced != 1"><small style="font-style:italic">Pas encore remplacé</small></td>
										<td class="cell">
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="valideReturnModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
													<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
												</svg>
											</b-button>
										</td>
									</tr>
								</tbody>
								<tbody v-if="noResult">
									<tr>
										<td colspan="13">Aucun résultat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<style>
	.offcanvas{
		position: absolute !important;
		width: 500px !important;
		max-width: 90% !important;
		transform: none !important;
		transition: none !important;
	}
	.offcanvas .table-striped tbody tr:nth-of-type(2n+1) {
		border-left: solid 2px rgb(47 87 136)
	}
	.offcanvas .table-striped tbody tr:nth-of-type(2n+1) hr{
		height: 3px;
		background-color:  rgb(47 87 136)
	}
	.offcanvas .table-striped tbody tr:nth-of-type(2n) {
		border-left: solid 2px #fa7507;
	}
	.offcanvas .table-striped tbody tr:nth-of-type(2n) hr{
		height: 3px;
		background-color:  #fa7507
	}
	.listeInfoMatch {
		margin: 0.5em
	}

	.multiselect__content-wrapper /*, .multiselect__element */ {
		width: fit-content !important;
		white-space: nowrap !important;
		overflow-x: hidden !important;
		text-overflow: ellipsis !important;
	}

	.multiselect__single {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis !important;
	}

	.multiselect__above {
		white-space: nowrap !important;
		overflow: hidden !important;
		word-break: break-all !important;
		text-overflow: ellipsis !important;
	}

	.multiselect {
		box-sizing: border-box;
		position: relative;
		width: 100%;
		max-height: 30px !important;
		text-align: center;
		background: none !important;
		color: #35495e;
		text-overflow: ellipsis !important;
	}

    .multiselect__placeholder {
        /*display: none !important;*/
        height: 30px !important;
        max-width: 100% !important;
        box-sizing: border-box !important;
        text-overflow: ellipsis !important;
		display: none !important;
    }

	.multiselect__single {
		/*display: none !important;*/
		display: inline-block !important;
		margin-top: -0.1em !important;
		font-size: 0.875rem !important;
		color: #212529 !important;
		white-space: nowrap !important;
		overflow: hidden !important;
		box-sizing: border-box !important;
		word-break: break-all !important;
		box-sizing: border-box !important;
		text-overflow: ellipsis !important;
		text-align: center !important;
		max-width: 100% !important;
	}

    .multiselect__input {
        /*display: none !important;*/
        display: inline-block !important;
        margin-top: -0.1em !important;
        font-size: 0.875rem !important;
        color: #212529 !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        box-sizing: border-box !important;
        word-break: break-all !important;
        box-sizing: border-box !important;
        text-overflow: ellipsis !important;
        text-align: center !important;
        width: 60% !important;
        max-width: 60% !important;
		text-align: center !important
    }

	.multiselect__select {
		height: 30px !important;
		text-overflow: ellipsis !important;
	}

	.multiselect__active > .multiselect__select {
		height: 30px !important;
		text-overflow: ellipsis !important;
	}

	.multiselect__current, .multiselect__select {
		/*display: none !important*/
		padding-bottom: 0.5em !important;
		text-overflow: ellipsis !important;
	}

    .multiselect__tags {
        margin-top: -0.1em !important;
        font-size: 0.875rem !important;
        min-height: 21px !important;
        max-height: 21px !important;
        display: inline-block !important;
        padding: 0 !important;
        border-radius: 0 !important;
        border: 0 !important;
        color: #212529 !important;
        width: 100% !important;
        box-sizing: border-box !important;
        text-overflow: ellipsis !important;
    }
    .multiselect__content {
        font-size: 0.875rem !important
    }

    .v-select {
        height: 30px !important;
        width: 100% !important
    }

    .vs__clear {
        display: block !important;
    }

    .vs__selected {
        word-wrap: normal !important;
        overflow: hidden !important;
        white-space: nowrap !important;
    }

    .vs__selected-options {
        word-wrap: normal !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        width: 60% !important;
    }

    .vs__dropdown-toggle {
        height: 30px !important;
        width: 100% !important;
        overflow: hidden !important;
        white-space: nowrap !important;
    }

    .vs__dropdown-menu {
        width: auto !important;
    }
</style>

<script>
import jwt_decode from "jwt-decode";
/*import Multiselect from "vue-multiselect";*/

export default {
	name: 'Return',
    components: {
/*		Multiselect*/
    },
	computed:
	{
        dateStartInputState: function () {
            var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
            if (reDate.test(this.dateStartInput)) { return true; }
            else { return false; }
        },
        dateEndInputState: function () {
            var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
            if (reDate.test(this.dateEndInput)) { return true; }
            else { return false; }
        },
		refereeDoubleInWeek1: function()
		{
			if (this.addAssignmentReferee1 != 0)
			{
				var hh = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);
				return hh.doubleInWeek;
			}
			return false;
		},
		refereeDoubleInDay1: function()
		{
			if (this.addAssignmentReferee1 != 0)
			{
				var hh = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);
				return hh.doubleInDay;
			}
			return false;
		},
		refereeDoubleInWeek2: function()
		{
			if (this.addAssignmentReferee2 != 0)
			{
				var hh = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);
				return hh.doubleInWeek;
			}
			return false;
		},
		refereeDoubleInDay2: function()
		{
			if (this.addAssignmentReferee2 != 0)
			{
				var hh = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);
				return hh.doubleInDay;
			}
			return false;
		}

		},
        filters: {
            truncate: function (text, length, suffix) {
                if (text.length > length) {
                    return text.substring(0, length) + suffix;
                } else {
                    return text;
                }
            }
        },
	data() {
		return {
			// Variable message alert
			dismissSecs: 4,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info: '',
			error: '',

            userIsDispatcher: false,
            userIsAdmin: false,

			listWeek: [],
			week: '',
			returnVisible: false,
			returns: [],
			returnSelected: {
				"referee1": {},
				"referee2": {},
				"match": {
					"sportsHall": {},
					"homeClub": {},
					"visitorClub": {},
					"poule":{}
				},
				"dispatcher": {},
				"admin": {},
				"file": {},
				"processingUser": {},
				"nouvelleDesignation": {
					"arbitre1": {},
					"arbitre2": {}
				}
			},
            status: 0,
			noResult: false,

			etatTriTableau: true,
            etatTriColumn: 0,

			dateStartInput: '',
			dateEndInput: '',

			listInfoDivision: [],
			listReferee: [],
			listGroup: [],
            divisionChoice: '',

            refereeChoice: {
                nationalNumber: 0,
                firstName: "Choisissez un arbitre",
            },
			groupChoice: '',

			listRefereeAvailable: [],

            filterListRefereePos1Available: [],
			filterListRefereePos2Available: [],
			groupReferee: "0",
			addReturnReferee: 0,
			
			refereeAssignments: [],
			selectedMatchReturn: 'null',
            motifReturnInput: '',

			returnComments: [],
			returnCommentInput: '',

			filterListReferee1Pos1Available:[],
			filterListReferee1Pos2Available:[],
			filterListReferee2Pos1Available:[],
			filterListReferee2Pos2Available: [],
			listGroupAvailable:[],
			addAssignmentReferee1:0,
			groupAssignmentReferee1:"0",
			addAssignmentReferee2:0,
			groupAssignmentReferee2: "0",

            matchesUnavailabilityReferee1: [],
			matchesUnavailabilityReferee2: [],

            previousAssignmentsReferee1: [],
            previousUnavailabilitiesReferee1: [],
            previousAssignmentsReferee2: [],
            previousUnavailabilitiesReferee2: [],

            referee1: [],
			referee2: [],
			voirArbitreDejaDesigne: false,
			voirArbitreRegional: false,
			forceDesignation : false,

			showAllReferee: false,
            showRefereeSuperior: false,
			update: false,
			hidden: true,
			newModal: false,
            myFilter: (option, label, search) => {
                let temp = search.toLowerCase();
                return option.firstName.toLowerCase().indexOf(temp) > -1 ||
                    option.lastName.toLowerCase().indexOf(temp) > -1
            }

		}
	},

	mounted()
	{
        this.checkToken();
        var jsonToken = jwt_decode(localStorage.token);
        this.roles = jsonToken.role;
        if (this.roles.indexOf('Retour') != -1) {
            this.userIsDispatcher = true;
        }
        if (this.roles.indexOf('AdminRetour') != -1) {
            this.userIsAdmin = true;
		}
		const wordings = ["Semaine dernière", "Semaine actuelle", "14 jours à venir", "30 jours à venir"];
		this.listWeek = [];


		wordings.forEach((item) => {
			let t = new Date(new Date().setDate(new Date().getDate()));
			//let t = new Date();
			
			if (item == "Semaine dernière")
			{
				t = new Date(new Date().setDate(new Date().getDate() - 7));
			}

			var monday = new Date(t.setDate(t.getDate() - t.getDay() + 1));
			var sunday = new Date(t.setDate(t.getDate() + (8 - t.getDay())));
			t = new Date(new Date().setDate(new Date().getDate()));

			if (item == "14 jours à venir")
			{
				sunday = new Date(t.setDate(t.getDate() + 14));
			}
			if (item == "30 jours à venir")
			{
				sunday = new Date(t.setDate(t.getDate() + 30));
			}

			var week = {
				wording: item,
				start: monday,
				end:sunday
			}

			this.listWeek.push(week);
		});
        
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.token
            }
        };

        this.$axios.get('https://api.clickandball.fr/division', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listInfoDivision = response.data;

                    if (this.listInfoDivision.length === 0) {
                        this.noResultDivision = true;
                    }
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
        this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listReferee = response.data;
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
            .catch(error => {
                this.showAlertWarning('Erreur : ' + error);
			});
        this.$axios.get('https://api.clickandball.fr/groupe', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listGroup = response.data;
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
            .catch(error => {
                this.showAlertWarning('Erreur : ' + error);
            });
	},

	methods: {

		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},
        fistNameWithLastName(option) {
            return `${option.firstName} ${option.lastName}`;
		},
        myFunction: function () {
            var input, filter, table, tr;
            input = document.getElementById("myInput");
            filter = input.value.toUpperCase();
            table = document.getElementById("myTable");
            tr = table.getElementsByTagName("tr");
            for (var i = 1; i < tr.length; i++) {
                var tds = tr[i].getElementsByTagName("td");
                var flag = false;
                for (var j = 0; j < tds.length; j++) {
                    var td = tds[j];
                    if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                        flag = true;
                    }
                }
                if (flag) {
                    tr[i].style.display = "";
                }
                else {
                    tr[i].style.display = "none";
                }
            }
        },
        sortTable(n) {
            let textA;
            let textB;
            let etatTri = this.etatTriTableau;
            let etatTriColumn = this.etatTriColumn;

            this.returns.sort(function (a, b) {
                switch (n) {
                    case 0:
                        textA = a.returnCreationDate.toUpperCase();
                        textB = b.returnCreationDate.toUpperCase();
                        etatTriColumn = 1;
                        break;
                    case 1:
                        textA = a.match.divisionId.toUpperCase();
                        textB = b.match.divisionId.toUpperCase();
                        etatTriColumn = 2;
                        break;
                    case 2:
                        textA = a.match.id;
                        textB = b.match.id;
                        etatTriColumn = 3;
                        break;
                    case 3:
                        textA = a.match.date.toUpperCase();
                        textB = b.match.date.toUpperCase();
                        etatTriColumn = 4;
                        break;
                    case 4:
                        textA = a.firstName.toUpperCase();
                        textB = b.firstName.toUpperCase();
                        etatTriColumn = 6;
                        break;
					case 5:
						textA = a.lastName.toUpperCase();
                        textB = b.lastName.toUpperCase();
                        etatTriColumn = 7;
                        break;
                    case 6:
                        textA = a.status;
                        textB = b.status;
                        etatTriColumn = 10;
                        break;
                    case 7:
                        textA = a.refereeIsReplaced;
                        textB = b.refereeIsReplaced;
                        etatTriColumn = 11;
                        break;
                    default:
                        break;
                }

                if (etatTri) {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }
                else {
                    return (textA > textB) ? -1 : (textA > textB) ? 0 : 1;
                }
            });

            this.etatTriTableau = !etatTri;
            this.etatTriColumn = etatTriColumn;
        },
		getDateString(date)
		{
			// prints date & time in YYYY-MM-DD format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
		},
		showReturnInDateSelected()
		{
			this.checkToken();

            if (this.refereeChoice.nationalNumber == 0) {
                this.refereeChoice.nationalNumber = ''
            }

			this.returns = [];
			this.returnVisible = true;
			this.noResult = false;
			//var week = this.listWeek.find(x => x.wording === this.week);

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/retour?start=' + this.dateStartInput + "&end=" + this.dateEndInput + "&division=" + this.divisionChoice + "&group=" + this.groupChoice + "&nationalNumber=" + this.refereeChoice.nationalNumber, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
						this.hidden = true;
					}
					else if (response.status == 200)
					{
						this.returns = response.data;

						if (this.returns.length === 0)
						{
							this.noResult = true;
						}

						this.hidden = false;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
						this.hidden = true;
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
					this.hidden = true;
				});
		},
		showAddReturnModal() {
			this.checkToken();
			this.$refs['addReturnModal'].show();

            this.addReturnReferee = 0;
            this.groupReferee = 0;
            this.filterListRefereePos1Available = [];
			this.filterListRefereePos2Available = [];
			this.showAllReferee = false;
			this.showRefereeSuperior = false;

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
						this.listRefereeAvailable = response.data;

						//Trier les arbitre par rapport à la distance
                        this.listRefereeAvailable.sort(function (a, b) {
                            if (a.lastName < b.lastName)
                                return -1;
                            if (a.lastName > b.lastName)
                                return 1;
                            return 0;
                        });

                        this.listRefereeAvailable.forEach(x => {
                            if (x.refereeLevel.id > 0 && x.status != 3) {
                                if (x.refereePosition === 1) {
                                    this.filterListRefereePos1Available.push(x);
                                }
                                else if (x.refereePosition === 2) {
                                    this.filterListRefereePos2Available.push(x);
                                }
                            }

                        })
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });

            this.$axios.get('https://api.clickandball.fr/groupe', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.listGroupAvailable = response.data;
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur: ' + error);
                });
		},
		addNewReturn() {

			this.checkToken();
            let refereeAssignmentId;
			this.$refs['addReturnModal'].hide();

			refereeAssignmentId = this.selectedMatchReturn;

            var reNumber = /^\d+$/;

            if (reNumber.test(this.selectedMatchReturn)) {
				let json = {
					nationalNumber: this.addReturnReferee,
                    refereeAssignmentId: refereeAssignmentId,
                    comment: this.motifReturnInput
                };
                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.token
                    }
                };

                this.$axios.post('https://api.clickandball.fr/retour', json, config)
                    .then(response => {
                        if (response.status == 200 && response.data.error) {
                            this.showAlertWarning('Erreur : ' + response.data.error);
                        }
                        else if (response.status == 200) {
                            this.showAlertSuccess("Retour sauvegardé");
                        }
                        else {
                            this.showAlertWarning('Erreur : ' + response.statusText);
                        }
                    })
                    .catch(error => {
                        this.showAlertWarning('Erreur : ' + error);
                    });
				this.motifReturnInput = '';
				}
		},
        filterReferee() {
            this.filterListRefereePos1Available = [];
            this.filterListRefereePos2Available = [];
            this.listRefereeAvailable.forEach(x => {
                if ((x.refereeLevel.id == this.groupReferee && x.refereePosition === 1) || (0 == this.groupReferee && x.refereePosition === 1)) {
                    this.filterListRefereePos1Available.push(x);
                }
                else if ((x.refereeLevel.id == this.groupReferee && x.refereePosition === 2) || (0 == this.groupReferee && x.refereePosition === 2)) {
                    this.filterListRefereePos2Available.push(x);
                }
            })
		},
		showRefereeMatches() {
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
			};

			let date = new Date()
			let end = new Date()
			end.setDate(date.getDate() + 30)

			this.$axios.get('https://api.clickandball.fr/designation?start=' + this.getDateString(date) + "&end=" + this.getDateString(end) + "&nationalNumber=" + this.addReturnReferee, config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
						this.refereeAssignments = response.data;

                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
		valideReturnModal(info)
		{
			this.newModal = true;
			this.checkToken();
			this.referee1 = '';
			this.referee2 = '';
			this.$refs['modalRetour'].show();
			this.returnSelected = info;

			this.addAssignmentReferee1 = 0;
			this.groupAssignmentReferee1 = 0;
			this.addAssignmentReferee2 = 0;
			this.groupAssignmentReferee2 = 0;
			this.filterListReferee1Pos1Available = [];
			this.filterListReferee1Pos2Available = [];
			this.filterListReferee2Pos1Available = [];
			this.filterListReferee2Pos2Available = [];
			this.showAllReferee = false;
			this.showRefereeSuperior = false;

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre?idMatch=' + info.match.id + "&divisionMatch=" + info.match.divisionId + "&pouleMatch=" + info.match.poule.id + "&voirTout=" + this.showAllReferee, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listRefereeAvailable = response.data;

						//Trier les arbitre par rapport à leur nom
						this.listRefereeAvailable.sort(function (a, b) {
                            if (a.lastName < b.lastName)
                                return -1;
                            if (a.lastName > b.lastName)
                                return 1;
                            return 0;
						});

						this.FiltreArbitreModalDesignation();
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});

			this.$axios.get('https://api.clickandball.fr/groupe', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listGroupAvailable = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur: ' + error);
				});
		},
		showReturnComments(info)
		{
			this.checkToken();
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			console.log("errt")
			this.$axios.get('https://api.clickandball.fr/retour/commentaire?type=1&id=' + info, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.returnComments = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur: ' + error);
				});
		},
		addNewComment()
		{
			this.checkToken();

			let selectedReturn = this.returnSelected.id

            var reNumber = /^\d+$/;

			if (reNumber.test(selectedReturn)) {
				let json = {
					type: 1,
                    id: selectedReturn,
                    comment: this.returnCommentInput
                };
                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.token
                    }
                };

                this.$axios.post('https://api.clickandball.fr/retour/commentaire', json, config)
                    .then(response => {
                        if (response.status == 200 && response.data.error) {
                            this.showAlertWarning('Erreur : ' + response.data.error);
                        }
                        else if (response.status == 200) {
                            this.showAlertSuccess("Commentaire ajouté");
							this.showReturnComments(selectedReturn);
                        }
                        else {
                            this.showAlertWarning('Erreur : ' + response.statusText);
                        }
                    })
                    .catch(error => {
                        this.showAlertWarning('Erreur : ' + error);
                    });
				this.returnCommentInput = '';
				}
		},
		deleteComment(info)
		{
			this.checkToken();

			let selectedReturn = this.returnSelected.id;
			let selectedComment = info.idComment;

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token,
                },
                data: {
                    "type": 1,
					"id": selectedComment
				}
            };

                this.$axios.delete('https://api.clickandball.fr/retour/commentaire', config)
                    .then(response => {
                        if (response.status == 200 && response.data.error) {
                            this.showAlertWarning('Erreur : ' + response.data.error);
                        }
                        else if (response.status == 200) {
                            this.showAlertSuccess("Commentaire ajouté");
							this.showReturnComments(selectedReturn);
                        }
                        else {
                            this.showAlertWarning('Erreur : ' + response.statusText);
                        }
                    })
                    .catch(error => {
                        this.showAlertWarning('Erreur : ' + error);
                    });
				this.returnCommentInput = '';
		},
		validatedReturn()
		{
			this.checkToken();
			this.$refs['modalRetour'].hide();
			let json = {
				id: this.returnSelected.id,
				status: this.status
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.put('https://api.clickandball.fr/retour', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Retour validé");
						if (this.hidden == false) {
                            this.showReturnInDateSelected();
                        }
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur: ' + error);
				});
		},
		deleteReturn()
		{
			this.checkToken();
			this.$refs['modalRetour'].hide();
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				},
				data: { id : [parseInt(this.returnSelected.id)] }
			};

			this.$axios.delete('https://api.clickandball.fr/retour', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Retour supprimé");
                        if (this.hidden == false) {
                            this.showReturnInDateSelected();
                        }
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		addAssignment()
		{
			this.checkToken();
			this.$refs['modalRetour'].hide();

			if(this.returnSelected.nationalNumber == this.returnSelected.referee1.nationalNumber)
			{
				if (this.returnSelected.nouvelleDesignationB) {
					this.addAssignmentReferee2 = this.returnSelected.nouvelleDesignation.arbitre2.nationalNumber;
				}
				else {
                    this.addAssignmentReferee2 = this.returnSelected.referee2.nationalNumber;
                }
				
			}
			else if (this.returnSelected.nationalNumber == this.returnSelected.referee2.nationalNumber)
			{
                if (this.returnSelected.nouvelleDesignationB) {
                    this.addAssignmentReferee1 = this.returnSelected.nouvelleDesignation.arbitre1.nationalNumber;
                }
                else {
                    this.addAssignmentReferee1 = this.returnSelected.referee1.nationalNumber;
                }
			}

			if (this.addAssignmentReferee1 == this.addAssignmentReferee2 && this.addAssignmentReferee1 != 0 && this.addAssignmentReferee2 != 0)
			{
				this.showAlertWarning('Problème sur la sélection des arbitres');
			}
			else
			{
				let json = {
					referee1: this.addAssignmentReferee1,
					referee2: this.addAssignmentReferee2,
					matchId: this.returnSelected.match.id,
					matchDivision: this.returnSelected.match.divisionId,
					matchPoule: this.returnSelected.match.poule.id,
					status: 1,
					forceDesignation: this.forceDesignation
				};
				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.post('https://api.clickandball.fr/designation', json, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.showAlertSuccess("Remplacement de l'arbitre sauvegardé");
                            this.showReturnInDateSelected();
						}
						else
						{
							this.showAlertWarning('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			}
		},
		// filterReferee1()
		// {
		// 	// this.filterListReferee1Pos1Available = [];
		// 	// this.filterListReferee1Pos2Available = [];
		// 	// this.listRefereeAvailable.forEach(x => {
		// 	// 	if ((x.refereeLevel.id == this.groupAssignmentReferee1 && x.refereePosition === 1) || (0 == this.groupAssignmentReferee1 && x.refereePosition === 1))
		// 	// 	{
		// 	// 		this.filterListReferee1Pos1Available.push(x);
		// 	// 	}
        //     //     else if ((x.refereeLevel.id == this.groupAssignmentReferee1 && x.refereePosition === 2) || (0 == this.groupAssignmentReferee1 && x.refereePosition === 2))
		// 	// 	{
		// 	// 		this.filterListReferee1Pos2Available.push(x);
		// 	// 	}
		// 	// })
		// },
		// filterReferee2()
		// {
		// 	// this.filterListReferee2Pos1Available = [];
		// 	// this.filterListReferee2Pos2Available = [];
		// 	// this.listRefereeAvailable.forEach(x => {
		// 	// 	if ((x.refereeLevel.id == this.groupAssignmentReferee2 && x.refereePosition === 1) || (0 == this.groupAssignmentReferee2 && x.refereePosition === 1))
		// 	// 	{
		// 	// 		this.filterListReferee2Pos1Available.push(x);
		// 	// 	}
		// 	// 	else if ((x.refereeLevel.id == this.groupAssignmentReferee2 && x.refereePosition === 2) || (0 == this.groupAssignmentReferee2 && x.refereePosition === 2))
		// 	// 	{
		// 	// 		this.filterListReferee2Pos2Available.push(x);
		// 	// 	}
		// 	// })
		// },
        getMonday(d) {
            d = new Date(d);
            var day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getSunday(d) {
            d = new Date(d);
            var day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6 : 1) + 6; // adjust when day is sunday
            return new Date(d.setDate(diff));
		},
        GetUnavailabilityReferee1() {
            this.referee1 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/designation/match?nationalNumber=' + this.referee1.nationalNumber + '&start=' + this.getDateString(this.getMonday(new Date(this.returnSelected.match.date))) + "&end=" + this.getDateString(this.getSunday(new Date(this.returnSelected.match.date))), config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.matchesUnavailabilityReferee1 = response.data;
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
        GetPreviousAssignmentReferee1() {
            this.referee1 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);

            var beginDate = '';
            beginDate = new Date(this.returnSelected.match.date)
            beginDate = beginDate.setDate(beginDate.getDate() - 30);
            var date = new Date(beginDate)

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/designation/match?nationalNumber=' + this.referee1.nationalNumber + '&start=' + this.getDateString(date) + "&last=6" + "&end=" + this.getDateString(this.getSunday(new Date(this.returnSelected.match.date))), config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.previousAssignmentsReferee1 = response.data;
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
        GetPreviousUnavailabilitiesReferee1() {
            this.referee1 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/designation/indisponibilite?start=' + this.getDateString(this.getMonday(new Date(this.returnSelected.match.date))) + "&end=" + this.getDateString(this.getSunday(new Date(this.returnSelected.match.date))) + "&nationalNumber=" + this.referee1.nationalNumber, config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.previousUnavailabilitiesReferee1 = response.data;
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
        GetUnavailabilityReferee2() {
            this.referee2 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/designation/match?nationalNumber=' + this.referee2.nationalNumber + '&start=' + this.getDateString(this.getMonday(new Date(this.returnSelected.match.date))) + "&end=" + this.getDateString(this.getSunday(new Date(this.returnSelected.match.date))), config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.matchesUnavailabilityReferee2 = response.data;
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
		},
        GetPreviousAssignmentReferee2() {
            this.referee2 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);

            var beginDate = '';
            beginDate = new Date(this.returnSelected.match.date)
            beginDate = beginDate.setDate(beginDate.getDate() - 30);
            var date = new Date(beginDate)

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/designation/match?nationalNumber=' + this.referee2.nationalNumber + '&start=' + this.getDateString(date) + "&last=6" + "&end=" + this.getDateString(this.getSunday(new Date(this.returnSelected.match.date))), config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.previousAssignmentsReferee2 = response.data;
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
        GetPreviousUnavailabilitiesReferee2() {
            this.referee2 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/designation/indisponibilite?start=' + this.getDateString(this.getMonday(new Date(this.returnSelected.match.date))) + "&end=" + this.getDateString(this.getSunday(new Date(this.returnSelected.match.date))) + "&nationalNumber=" + this.referee2.nationalNumber, config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.previousUnavailabilitiesReferee2 = response.data;
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
		downloadCSV()
		{
			this.checkToken();
			
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
					'Content-Disposition': 'attachment'
				},
				responseType: 'blob'
			};

			this.$axios.get('https://api.clickandball.fr/retour/extraction?start=' + this.dateStartInput + "&end=" + this.dateEndInput, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', 'Retours.csv');
						document.body.appendChild(link);
						link.click();
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		downloadJustificatif(id)
		{
			this.checkToken();
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
					'Content-Disposition': 'attachment'
				},
				responseType: 'blob'
			};
			this.$axios.get('https://api.clickandball.fr/retour/importfichier?idRetour=' + id, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						window.open(url, "_blank");
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
        assignReturn(id) {
			this.checkToken();
			let json = {
				id: id
			};
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token,
                },
            };
            this.$axios.post('https://api.clickandball.fr/retour/attributionretour', json , config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.showAlertSuccess("Retour affecté au répartiteur");
						if (this.hidden == false) {
                            this.$refs['modalRetour'].hide();
                            this.showReturnInDateSelected();
                        }
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
		FiltreArbitreModalDesignation()
		{
			this.filterListReferee1Pos1Available = [];
			this.filterListReferee2Pos1Available = [];
			this.filterListReferee1Pos2Available = [];
			this.filterListReferee2Pos2Available = [];
			let listRefereeAvailable2 = [];

			// Trie en fonction des groupes de niveau des arbitres
			this.listRefereeAvailable.forEach(x => {
				if ((x.refereeLevel.id == this.groupAssignmentReferee1) || (0 == this.groupAssignmentReferee1))
				{
					listRefereeAvailable2.push(x);
				}
			});

			listRefereeAvailable2.forEach(x => {
				console.log(x.nationalNumber)
				let voirArbitreRegion = document.getElementById("voirArbitreRegional").checked;
				let voirArbitreDesigne = document.getElementById("voirArbitreDejaDesigne").checked;
				let forceDesignation = document.getElementById("forceDesignation").checked;

				// Visualisation des arbitres disponible
				if (((x.nbMatchArbitre == 0 && !x.canDouble) || //Zéro match + ne double pas
				(x.nbMatchArbitre < 2 && x.canDouble)) && //Zéro ou un match et double
				x.status != 3 && x.refereePosition == 1 && x.arbitreDispo &&
				!voirArbitreRegion && !voirArbitreDesigne && !forceDesignation)
				{
					this.filterListReferee1Pos1Available.push(x);
					this.filterListReferee2Pos1Available.push(x);
				}
				else if (((x.nbMatchArbitre == 0 && !x.canDouble) || 
				(x.nbMatchArbitre < 2 && x.canDouble)) && 
				x.status != 3 && x.refereePosition == 2 && x.arbitreDispo &&
				!voirArbitreRegion && !voirArbitreDesigne && !forceDesignation)
				{
					this.filterListReferee1Pos2Available.push(x);
					this.filterListReferee2Pos2Available.push(x);
				}

				// Visualisation déjà désigné - Afficher tous les arbitres
				else if (voirArbitreDesigne && x.refereePosition == 1 && x.status != 3 && x.arbitreDispo)
				{
					this.filterListReferee1Pos1Available.push(x);
					this.filterListReferee2Pos1Available.push(x);
				}
				else if (voirArbitreDesigne && x.refereePosition == 2 && x.status != 3 && x.arbitreDispo)
				{
					this.filterListReferee1Pos2Available.push(x);
					this.filterListReferee2Pos2Available.push(x);
				}

				// Visualisation des arbritres régional
				else if (voirArbitreRegion && x.status == 3 && x.refereePosition == 1 && x.arbitreDispo)
				{
					this.filterListReferee1Pos1Available.push(x);
					this.filterListReferee2Pos1Available.push(x);
				}
				else if (voirArbitreRegion && x.status == 3 && x.refereePosition == 2 && x.arbitreDispo)
				{
					this.filterListReferee1Pos2Available.push(x);
					this.filterListReferee2Pos2Available.push(x);
				}

				// Visualisation de tous les arbitres - Forcer l'enregistrement
				else if (forceDesignation && x.refereePosition == 1 )
				{
					this.filterListReferee1Pos1Available.push(x);
					this.filterListReferee2Pos1Available.push(x);
				}
				else if (forceDesignation && x.refereePosition == 2)
				{
					this.filterListReferee1Pos2Available.push(x);
					this.filterListReferee2Pos2Available.push(x);
				}
			});


			// Log
			let dispo = [];
			let all = [];
			this.filterListReferee1Pos1Available.forEach(x => {
				dispo.push(true)
				all.push([x.nbMatchArbitre, x.canDouble, x.arbitreDispo, x.status, x.refereeLevel.id, x.nationalNumber])
				// console.log(x.nbMatchArbitre)
				// console.log(x.arbitreDispo)
				// console.log(x.status)
			});

			console.log("Dispo =>")
			console.log(dispo)

			console.log("All =>")
			console.log(all)

			// this.filterListReferee1Pos2Available.forEach(x => {
			// 	console.log(x.nbMatchArbitre)
			// 	console.log(x.arbitreDispo)
			// 	console.log(x.status)
			// });
		}
	}
}
</script>
