<template>
	<div class="User">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Gestion des <span v-if="roleAdminUtilisateur">utilisateurs</span><span v-else>arbitres</span></h1>

		<b-modal ref="modalUtilisateur" id="modal-xl" size="xl" title="Large Modal"  hide-footer hide-header>
			<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalUtilisateur'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8c4.408 0 8-3.584 8-8s-3.592-8-8-8zm5.284 3.688a6.802 6.802 0 0 1 1.545 4.251c-.226-.043-2.482-.503-4.755-.217-.052-.112-.096-.234-.148-.355-.139-.33-.295-.668-.451-.99 2.516-1.023 3.662-2.498 3.81-2.69zM8 1.18c1.735 0 3.323.65 4.53 1.718-.122.174-1.155 1.553-3.584 2.464-1.12-2.056-2.36-3.74-2.551-4A6.95 6.95 0 0 1 8 1.18zm-2.907.642A43.123 43.123 0 0 1 7.627 5.77c-3.193.85-6.013.833-6.317.833a6.865 6.865 0 0 1 3.783-4.78zM1.163 8.01V7.8c.295.01 3.61.053 7.02-.971.199.381.381.772.555 1.162l-.27.078c-3.522 1.137-5.396 4.243-5.553 4.504a6.817 6.817 0 0 1-1.752-4.564zM8 14.837a6.785 6.785 0 0 1-4.19-1.44c.12-.252 1.509-2.924 5.361-4.269.018-.009.026-.009.044-.017a28.246 28.246 0 0 1 1.457 5.18A6.722 6.722 0 0 1 8 14.837zm3.81-1.171c-.07-.417-.435-2.412-1.328-4.868 2.143-.338 4.017.217 4.251.295a6.774 6.774 0 0 1-2.924 4.573z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">
								<div v-if="checkAddUser">Ajout d'un nouvel utilisateur</div>
								<div v-if="verifMajUtilisateur">Modifier un utilisateur</div>
								<div v-if="verifMajArbitre">Modifier un arbitre</div>
								<div v-if="viewUser">Voir un utilisateur</div>
							</h4>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!accountActivation && !checkAddUser" class="notification-type mb-2"><span class="badge bg-info">Utilisateur inactif</span></div>
			<div v-if="!accountIsActivated && !checkAddUser" class="notification-type mb-2"><span class="badge bg-danger">Compte désactivé</span></div>
			<div v-if="roleAdminUtilisateur && !checkAddUser" class="app-card app-card-notification shadow-sm mb-4">
				<div class="app-card-header px-4 py-3">
					<div class="row g-3 align-items-center">
						<div class="col-12 col-lg-auto text-lg-left">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
								<path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
							</svg>
						</div><!--//col-->
						<div class="col-12 col-lg-auto text-lg-left">

							<h4 class="notification-title mb-1">Informations de connexion</h4>

							<ul class="notification-meta list-inline mb-0">
								<li class="list-inline-item"><strong>Activation du compte : </strong><span v-if="accountActivation">{{ accountActivation }}</span><span v-else style="color: darkred;">Compte non activé</span></li>
								<li class="list-inline-item">|</li>
								<li class="list-inline-item"><strong>Dernière connexion à Click&Ball : </strong><span v-if="lastConnection">{{ lastConnection }}</span><span v-else style="color: darkred;">L'utilisateur ne s'est jamais connecté</span></li>
							</ul>
						</div><!--//col-->
					</div><!--//row-->
				</div><!--//app-card-header-->
				<div class="app-card-body p-4">
					<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">
						<div class="form-check form-switch mb-3">
							<label for="accountIsActivated" style="margin-right: 0.75em"><strong>Compte actif ?</strong></label>
							<input class="form-check-input" type="checkbox" v-model="accountIsActivated" name="accountIsActivated" style="margin: 0.4em" :value="accountIsActivated" :disabled="viewUser" /><br />
							<small style="color: rgb(42, 87, 136)">Permet de bloquer d'activer ou bloquer l'accès au compte de l'utilisateur</small>
						</div>

					</div>
				</div>
				<div v-if="!accountActivation" class="app-card-footer px-4 py-3">
					<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px;margin-top: -0.5em; margin-bottom: -0.5em" v-on:click="newActivation()">
						Renvoyer le lien d'activation
					</b-button>
				</div><!--//app-card-footer-->
			</div>
			<div class="accordion" role="tablist" style="margin-bottom: 0">
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.administratif type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" id="title-legende">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fa7507" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
									</svg><span style="color: #fa7507;"> Informations administratives</span>
								</h4>
								<ul class="notification-meta list-inline mb-0" style="color:">
									<li class="list-inline-item">
										Informations nécessaires pour l'identification de l'utilisateur
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse visible id="administratif" accordion="my-accordion" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -0.5em; margin-top: -0.5em">
								<div class="row">
									<div class="col-md-10 offset-md-1">
										<div class="row justify-content-evenly">
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Nom :
													</label>
													<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="lastName" :disabled="!checkAddUser"></b-form-input>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Prénom :
													</label>
													<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="firstName" :disabled="!checkAddUser"></b-form-input>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Date de naissance :
													</label>
													<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateState" v-model="birthday" type="date" required :disabled="!checkAddUser"></b-form-input>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-10 offset-md-1">
										<div class="row justify-content-evenly">
											<div class="col-md-12 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Adresse :
													</label>
													<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="address" v-on:keyup="searchAddressAPIEtalab()" v-on:keydown="searchAddressAPIEtalab()" placeholder="Saisissez une adresse..." :disabled="viewUser"></b-form-input>
												</div>
												<div v-if="listSuggestedAddress.length > 0">
													<ul>
														<li v-for="item in listSuggestedAddress" :key="item">
															<span @click="selectAddress(item)">{{ item }}</span>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-10 offset-md-1">
										<div class="row justify-content-evenly">
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														N° national :
													</label>
													<b-form-input type="number" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="nationalNumber" :disabled="!checkAddUser"></b-form-input>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														N° de licence :
													</label>
													<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="permitNumber" :disabled="!checkAddUser"></b-form-input>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Club :
													</label>
													<v-select :options="listClub" placeholder="Choissisez un club" label="name" v-model="club" required :disabled="verifMajArbitre || viewUser">
														<template v-slot:option="option">
															{{ option.name }}
														</template>
														<template v-slot:no-options="{ search, searching }">
															<template v-if="searching">
																Pas de résultats pour 
																<em>{{ search }}</em>.
															</template>
															<em v-else style="opacity: 0.5">Ecrivez pour rechercher un club</em>
														</template>
													</v-select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</b-collapse>
					<div block v-b-toggle.contact type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" id="title-legende">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fa7507" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
									</svg><span style="color: #fa7507;"> Informations de contact</span>
								</h4>
								<ul class="notification-meta list-inline mb-0" style="color:">
									<li class="list-inline-item">
										Email, numéro de téléphone
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse visible id="contact" accordion="my-accordion2" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -0.5em; margin-top: -0.5em">
								<div class="row">
									<div class="col-md-10 offset-md-1">
										<div class="row justify-content-evenly">
											<div class="col-md-6 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Adresse email :
													</label>
													<b-form-input :state="emailState" v-model="email" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="email" required :disabled="verifMajArbitre || viewUser"></b-form-input>
												</div>
											</div>
											<div class="col-md-6 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														N° de téléphone :
													</label>
													<b-form-input :state="phoneState" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="phone" type="text" required :disabled="viewUser"></b-form-input>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</b-collapse>
					<div v-if="!verifMajArbitre" block v-b-toggle.roles type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" id="title-legende">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fa7507" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M4.5 9a3.5 3.5 0 1 0 0 7h7a3.5 3.5 0 1 0 0-7h-7zm7 6a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-7-14a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm2.45 0A3.49 3.49 0 0 1 8 3.5 3.49 3.49 0 0 1 6.95 6h4.55a2.5 2.5 0 0 0 0-5H6.95zM4.5 0h7a3.5 3.5 0 1 1 0 7h-7a3.5 3.5 0 1 1 0-7z" />
									</svg><span style="color: #fa7507;"> Rôles de l'utilisateur</span>
								</h4>
								<ul class="notification-meta list-inline mb-0" style="color:">
									<li class="list-inline-item">
										Permet de définir les permissions de l'utilisateur
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse v-if="!verifMajArbitre" visible id="roles" accordion="my-accordion3" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -0.5em">
								<div class="row">
									<div class="col-md-10 offset-md-1">
										<div class="row justify-content-evenly">
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<div class="form-check form-switch mb-3">
														<span>
															<input class="form-check-input" type="checkbox" v-model="ArbitreDesignation" style="margin: 0.4em; width: 40px; height: 20px;" :disabled="viewUser" />
															<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Arbitre désignation</strong></label>
														</span>
													</div>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<div class="form-check form-switch mb-3">
														<span>
															<input class="form-check-input" type="checkbox" v-model="ArbitreRetour" style="margin: 0.4em; width: 40px; height: 20px;" :disabled="viewUser" />
															<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Arbitre retour</strong></label>
														</span>
													</div>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<div class="form-check form-switch mb-3">
														<span>
															<input class="form-check-input" type="checkbox" v-model="Utilisateur" style="margin: 0.4em; width: 40px; height: 20px;" :disabled="viewUser" />
															<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Gestion des arbitres</strong></label>
														</span>
													</div>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<div class="form-check form-switch mb-3">
														<span>
															<input class="form-check-input" type="checkbox" v-model="AdminUtilisateur" style="margin: 0.4em; width: 40px; height: 20px;" :disabled="viewUser" />
															<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Gestion des utilisateurs</strong></label>
														</span>
													</div>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<div class="form-check form-switch mb-3">
														<span>
															<input class="form-check-input" type="checkbox" v-model="Repartiteur" style="margin: 0.4em; width: 40px; height: 20px;" :disabled="viewUser" />
															<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Répartiteur</strong></label>
														</span>
													</div>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<div class="form-check form-switch mb-3">
														<span>
															<input class="form-check-input" type="checkbox" v-model="AdminRepartiteur" style="margin: 0.4em; width: 40px; height: 20px;" :disabled="viewUser" />
															<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Administrateur répartiteur</strong></label>
														</span>
													</div>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<div class="form-check form-switch mb-3">
														<span>
															<input class="form-check-input" type="checkbox" v-model="Retour" style="margin: 0.4em; width: 40px; height: 20px;" :disabled="viewUser" />
															<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Gestion des retours</strong></label>
														</span>
													</div>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<div class="form-check form-switch mb-3">
														<span>
															<input class="form-check-input" type="checkbox" v-model="AdminRetour" style="margin: 0.4em; width: 40px; height: 20px;" :disabled="viewUser" />
															<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Gestion admin des retours</strong></label>
														</span>
													</div>
												</div>
											</div>
											<div class="col-md-4 pr-1">
												<div class="form-group">
													<div class="form-check form-switch mb-3">
														<span>
															<input class="form-check-input" type="checkbox" v-model="AdminEntite" style="margin: 0.4em; width: 40px; height: 20px;" :disabled="viewUser" />
															<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Administrateur de l'entité</strong></label>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</b-collapse>
					<div v-if="ArbitreDesignation || ArbitreRetour" block v-b-toggle.referee type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" id="title-legende">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fa7507" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="m7.788 2.34-.799 1.278A.25.25 0 0 0 7.201 4h1.598a.25.25 0 0 0 .212-.382l-.799-1.279a.25.25 0 0 0-.424 0Zm0 11.32-.799-1.277A.25.25 0 0 1 7.201 12h1.598a.25.25 0 0 1 .212.383l-.799 1.278a.25.25 0 0 1-.424 0ZM3.617 9.01 2.34 8.213a.25.25 0 0 1 0-.424l1.278-.799A.25.25 0 0 1 4 7.201V8.8a.25.25 0 0 1-.383.212Zm10.043-.798-1.277.799A.25.25 0 0 1 12 8.799V7.2a.25.25 0 0 1 .383-.212l1.278.799a.25.25 0 0 1 0 .424Z" />
										<path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v3a.5.5 0 0 1-.5.5h-3A1.5 1.5 0 0 0 0 6.5v3A1.5 1.5 0 0 0 1.5 11h3a.5.5 0 0 1 .5.5v3A1.5 1.5 0 0 0 6.5 16h3a1.5 1.5 0 0 0 1.5-1.5v-3a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 0 16 9.5v-3A1.5 1.5 0 0 0 14.5 5h-3a.5.5 0 0 1-.5-.5v-3A1.5 1.5 0 0 0 9.5 0h-3ZM6 1.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3A1.5 1.5 0 0 0 11.5 6h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a1.5 1.5 0 0 0-1.5 1.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-3A1.5 1.5 0 0 0 4.5 10h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 0 6 4.5v-3Z" />
									</svg><span style="color: #fa7507;"> Conditions de désignation</span>
								</h4>
								<ul class="notification-meta list-inline mb-0" style="color:">
									<li class="list-inline-item">
										Permet de définir les conditions selon lesquelles l'arbitre est désigné
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse v-if="ArbitreDesignation || ArbitreRetour" visible id="referee" accordion="my-accordion4" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -0.5em">
								<div class="row">
									<div class="col-md-10 offset-md-1">
										<div class="row justify-content-evenly">
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														L'arbitre est désignable
													</label>
													<select class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="isDesignatable" required :disabled="verifMajArbitre || viewUser">
														<option v-bind:value="true">Oui</option>
														<option v-bind:value="false">Non</option>
													</select>
												</div>
											</div>
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Commentaire
													</label>
													<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px" placeholder="A venir" disabled />
												</div>
											</div>
										</div>
										<hr />
										<div class="row justify-content-evenly">
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Statut de l'arbitre
													</label>
													<select class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="status" required :disabled="verifMajArbitre || viewUser">
														<option disabled value="12">Choisissez un statut</option>
														<option v-bind:value="0" style="font-style:italic">Non Applicable</option>
														<option v-bind:value="2">Arbitre stagiaire</option>
														<option v-bind:value="1">Arbitre départemental</option>
														<option v-bind:value="3">Arbitre région ou CF</option>
													</select>
												</div>
											</div>
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Distance maximum à parcourir
													</label>
													<b-form-input type="number" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="distanceMax" :disabled="viewUser"></b-form-input>
												</div>
											</div>
										</div>
										<hr />
										<div class="row justify-content-evenly">
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Groupe
													</label>
													<select class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="refereeLevel" required :disabled="viewUser">
														<option disabled value="0">Choisissez un groupe</option>
														<option v-for="item in listGroup" :key="item.id" :value="item.id">
															{{ item.wording }}
														</option>
													</select>
												</div>
											</div>
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														Position
													</label>
													<select class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="refereePosition" required :disabled="viewUser">
														<option value="1">1</option>
														<option value="2">2</option>
													</select>
												</div>
											</div>
										</div>
										<hr />
										<div class="row justify-content-evenly">
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														L'arbitre est un tuteur ?
													</label>
													<select class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="tutor" required :disabled="viewUser">
														<option v-bind:value="true">Oui</option>
														<option v-bind:value="false">Non</option>
													</select>
												</div>
											</div>
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														L'arbitre peut-il doubler ?
													</label>
													<select class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="canDouble" required :disabled="viewUser">
														<option v-bind:value="true">Oui</option>
														<option v-bind:value="false">Non</option>
													</select>
												</div>
											</div>
										</div>
										<hr />
										<div class="row justify-content-evenly text-center">
											<div class="col-md-12">
												<div class="form-group">
													<label class="form-label" style="font-weight: bold;">
														<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
														</svg>
														L'arbitre doit être désigné avec :
													</label>
												</div>
											</div>
										</div>
										<div class="row justify-content-evenly">
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<b-form-select v-model="groupRefereeMandatory" @change="filterRefereeMandatory()" class="form-select form-select-sm ml-auto d-inline-flex text-center" :disabled="viewUser">
														<b-form-select-option :value="0">Choisir un groupe</b-form-select-option>
														<b-form-select-option v-for="r in listGroup" :key="r.id" :value="r.id">{{ r.wording }}</b-form-select-option>
													</b-form-select>
												</div>
											</div>
											<div class="col-md-5 pr-1">
												<div class="form-group">
													<b-form-select v-model="colleagueMandatory" class="form-select form-select-sm ml-auto d-inline-flex text-center" :disabled="viewUser">
														<b-form-select-option :value=0>Aucun arbitre</b-form-select-option>
														<b-form-select-option disabled :value=-1>--- Arbitre position 1 ---</b-form-select-option>
														<b-form-select-option v-for="r in listRefereeMandatoryPos1" :key="r.nationalNumber" :value="r.nationalNumber">{{ r.firstName }} {{ r.lastName }}</b-form-select-option>
														<b-form-select-option disabled :value=-1>--- Arbitre position 2 ---</b-form-select-option>
														<b-form-select-option v-for="r in listRefereeMandatoryPos2" :key="r.nationalNumber" :value="r.nationalNumber">{{ r.firstName }} {{ r.lastName }}</b-form-select-option>
													</b-form-select>
												</div>
											</div>
										</div>
										<hr />
									</div>
								</div>
							</div>
						</div>
					</b-collapse>
				</div>
				<div class="text-center">
					<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="AjouterUtilisateur()" v-if="checkAddUser">Ajouter un nouvel utilisateur</b-button>
					<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; margin-right: 0.5em;" v-on:click="MajUtilisateur()" v-if="verifMajUtilisateur && !viewUser">Enregistrer les modifications</b-button>
					<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; margin-right: 0.5em;" v-on:click="majArbitre()" v-if="verifMajArbitre  && !viewUser">Enregistrer les modifications</b-button>
				</div>
			</div>
		</b-modal>



		<b-alert :show="alertSuccessCountDown" variant="success" @dismissed="alertSuccessCountDown=0" @dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success" :max="dismissSecs" :value="alertSuccessCountDown" height="4px">

			</b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown" variant="warning" @dismissed="alertWarningCountDown=0" @dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning" :max="dismissSecs" :value="alertWarningCountDown" height="4px">

			</b-progress>
		</b-alert>

		<div class="accordion24" role="tablist">
			<div class="app-card shadow-sm mb-4 border-left-decoration">
				<div class="inner">
					<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<h5 class="title align-middle" style="color: rgb(47 87 136)">
							<i class="fas fa-search-plus"></i>
							Actions
						</h5>
					</div>
					<b-collapse visible id="actions" accordion="my-accordion24" role="tabpanel" class="app-card-body p-4">
						<div class="row g-2 justify-content-center justify-content-md-end">
							<div class="col-12 col-sm-12 col-lg-10" style=" margin-bottom: -1em">
								<div class="row">
									<div class="d-block d-sm-none text-center">
										<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin-bottom: 1.5em" v-if="roleAdminUtilisateur" v-on:click="AjouterUtilisateurModal()">
											Ajouter un utilisateur
										</b-button>
									</div>
									<div class="d-none d-sm-block d-lg-none text-center" style="margin-bottom: 1em">
										<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin-bottom: 1.5em" v-if="roleAdminUtilisateur" v-on:click="AjouterUtilisateurModal()">
											Ajouter un utilisateur
										</b-button>
									</div>
									<div class="d-none d-sm-none d-md-none d-lg-block" style="margin-bottom: 1em">
										<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px" v-if="roleAdminUtilisateur" v-on:click="AjouterUtilisateurModal()">
											Ajouter un utilisateur
										</b-button>
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-2 align-center align-items-middle" style="text-align: center;">
								<a class="btn app-btn-secondary" style="color: #fa7507; border: 1px solid #fa7507; border-radius: .25rem;" v-on:click="downloadCSV()">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
										<path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
									</svg>
									Extraction CSV
								</a>
							</div><!--//col-->
						</div><!--//row-->
					</b-collapse><!--//app-card-body-->
				</div><!--//inner-->
			</div>
		</div>

		<div class="tab-content" id="orders-table-tab-content">
			<form class="app-search-form">
				<input name="search" type="text" id="myInput" @keyup="RechercheElementDansTableau()" placeholder="Recherchez un nom, un prénom, un club" title="Recherche textuelle" class="form-control search-input" style="height: 50px; margin-bottom: 1em">
				<button disabled class="btn search-btn btn-primary" value="Search" style="padding-top: 1em">
					<svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
						<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
					</svg><!-- <i class="fas fa-search"></i> -->
				</button>
			</form>

			<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
				<div class="app-card app-card-orders-table shadow-sm mb-5">
					<div class="app-card-body">
						<div class="table-responsive table-stiped">
							<table id="myTable" class="table table-striped table-hover mb-0 text-center">
								<thead class="header">
									<tr>
										<th class="mb-2 text-primary" v-on:click="sortTable(0)">
											Numéro de licence
											<span v-if="etatTriColumn == 1 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 1 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(1)">
											Prénom
											<span v-if="etatTriColumn == 2 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 2 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(2)">
											Nom
											<span v-if="etatTriColumn == 3 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 3 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(3)">
											Club
											<span v-if="etatTriColumn == 4 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 4 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(4)">
											Ville
											<span v-if="etatTriColumn == 5 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 5 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary">Arbitre</th>
										<th class="mb-2 text-primary">Statut</th>
										<th class="mb-2 text-primary">Groupe</th>
										<th class="mb-2 text-primary">Numéro de téléphone</th>
										<th class="mb-2 text-primary">Actions</th>

									</tr>
								</thead>
								<tbody v-if="!noResult">
									<tr v-for="item in listUsers" :key="item.nationalNumber">
										<td class="cell">{{ item.permitNumber }}</td>
										<td class="cell">{{ item.firstName }}</td>
										<td class="cell">{{ item.lastName }}</td>
										<td class="cell">{{ item.club.name }}</td>
										<td class="cell">{{ item.city }}</td>
										<td class="cell" v-if="item.role.find(x => x === 'ArbitreDesignation')">Oui</td>
										<td class="cell" v-else>Non</td>
										<td class="cell" v-if="item.status == 0"><small style="font-style:italic">NA</small></td>
										<td class="cell" v-if="item.status == 2">Arbitre stagiaire</td>
										<td class="cell" v-if="item.status == 1">Arbitre départemental</td>
										<td class="cell" v-if="item.status == 3">Arbitre région ou CF</td>
										<td class="cell">{{ item.refereeLevel.wording}}</td>
										<td class="cell">{{ item.phone }}</td>
										<td class="cell">
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="InformationUtilisateurModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" class="bi bi-info-lg" viewBox="0 0 16 16">
													<path d="m10.277 5.433-4.031.505-.145.67.794.145c.516.123.619.309.505.824L6.101 13.68c-.34 1.578.186 2.32 1.423 2.32.959 0 2.072-.443 2.577-1.052l.155-.732c-.35.31-.866.434-1.206.434-.485 0-.66-.34-.536-.939l1.763-8.278zm.122-3.673a1.76 1.76 0 1 1-3.52 0 1.76 1.76 0 0 1 3.52 0z" />
												</svg>
											</b-button>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="MajUtilisateurModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" class="bi bi-pencil" viewBox="0 0 16 16">
													<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
												</svg>
											</b-button>
										</td>
									</tr>
								</tbody>
								<tbody v-if="noResult">
									<tr>
										<td colspan="9">Aucun résultat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<style>
    .form-group {
        margin-bottom: 0.25em !important;
    }
    .listeInfoMatch {
        margin: 0.5em
    }
    .vs__clear {
		display: none !important;
    }
    .v-select {
		max-height: 30px !important;
    }

    .vs__selected {
        word-wrap: normal !important;
        overflow: hidden !important;
        white-space: nowrap !important;
    }

    .vs__selected-options {
        word-wrap: normal !important;
        overflow: hidden !important;
        white-space: nowrap !important;
		width: 80% !important;
    }

    .vs__dropdown-toggle {
        max-height: 30px !important;
        overflow: hidden !important;
        white-space: nowrap !important;
    }
</style>

<script>
	import jwt_decode from "jwt-decode";
    import Vue from 'vue'
    import vSelect from 'vue-select'

    Vue.component('v-select', vSelect)
	import 'vue-select/dist/vue-select.css';


export default {
	name: 'User',

	computed:
	{
		dateState: function()
		{
			var reDate = /^((20|19)\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			if (reDate.test(this.birthday))
			{
				return true;
			}
			else 
			{
				return false;
			}
		},
		emailState: function()
		{
			var reEmail = /^[\w.-]+@[a-zA-Z0-9-.]+.[a-zA-Z]{2,4}$/;
			if (reEmail.test(this.email))
			{
				return true;
			}
			else 
			{
				return false;
			}
		},
		phoneState: function()
		{
			var rePhone = /^([+]33|0033|0)[0-9]{9}$/;
			return (rePhone.test(this.phone) || this.phone === "")
		}
	},
	data() {
		return {
			// Variable message alert
			dismissSecs: 4,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error:'',

			roleUtilisateur: false,
			roleAdminUtilisateur: false,

			apiGetInfoIsUpdate: false,

			etatTriTableau: true,
			etatTriColumn: 0,

			listUsers:[],
			listClub:[],
			listGroup:[],
			listRefereeAvailable: [],
			groupRefereeMandatory:0,
			groupRefereeOptionnel:0,
			listRefereeMandatoryPos1: [],
			listRefereeMandatoryPos2: [],
			listRefereeOptionnelPos1: [],
			listRefereeOptionnelPos2: [],
			noResult: false,

            accountActivation:"",
            lastConnection:"",

			checkAddUser:false,
			verifMajUtilisateur:false,
			verifMajArbitre:false,
			viewUser:false,

			nationalNumber: 0,
			permitNumber:"",
			firstName:"",
			lastName:"",
			birthday:"",
			club:"",
			address:"",
			nouvelleAddress:false,
			listSuggestedAddress:[],
			email:"",
			phone: "",
			status: 0,
			distanceMax:0,
			refereeLevel:0,
			refereePosition:1,
			canDouble:false,
			tutor:false,
			tutored:false,
			isDesignatable:true,
			colleagueMandatory:0,
			optionalColleague: 0,
			accountIsActivated: true,
			daySinceLastConnection: 0,
			clubId: "",

			// Rôles
			ArbitreDesignation:false,
			ArbitreRetour:false,
			Utilisateur:false,
			AdminUtilisateur:false,
			Repartiteur:false,
			AdminRepartiteur:false,
			Retour:false,
			AdminRetour:false,
			AdminEntite:false
		}
	},

	mounted()
	{
		this.chargementDeLaPage();
	},

	methods:
	{
		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},

		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},

		chargementDeLaPage() {
            this.checkToken();
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/utilisateur', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
					else if (response.status == 200) {

						
						this.listUsers = response.data;

                        if (this.listUsers.length === 0) {
                            this.noResult = true;
                        }
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })

            var jsonToken = jwt_decode(localStorage.token);
            this.roles = jsonToken.role;
            if (this.roles.indexOf('Utilisateur') != -1) {
                this.roleUtilisateur = true;
            }
            if (this.roles.indexOf('AdminUtilisateur') != -1) {
                this.roleAdminUtilisateur = true;
            }
		},
		RechercheElementDansTableau()
		{
			var input, filter, table, tr;
			input = document.getElementById("myInput");
			filter = input.value.toUpperCase();
			table = document.getElementById("myTable");
			tr = table.getElementsByTagName("tr");
			for (var i = 1; i < tr.length; i++)
			{
				var tds = tr[i].getElementsByTagName("td");
				var flag = false;
				for (var j = 0; j < tds.length; j++)
				{
					var td = tds[j];
					if (td.innerHTML.toUpperCase().indexOf(filter) > -1)
					{
						flag = true;
					}
				}
				if (flag)
				{
					tr[i].style.display = "";
				}
				else
				{
					tr[i].style.display = "none";
				}
			}
		},
		sortTable (n)
		{
			let textA;
			let textB;
			let etatTri = this.etatTriTableau;
			let etatTriColumn = this.etatTriColumn;

			this.listUsers.sort(function(a, b) {
				switch (n) {
					case 0:
						textA = a.permitNumber.toUpperCase();
						textB = b.permitNumber.toUpperCase();
						etatTriColumn = 1;
						break;
					case 1:
						textA = a.firstName.toUpperCase();
						textB = b.firstName.toUpperCase();
                        etatTriColumn = 2;
						break;
					case 2:
						textA = a.lastName.toUpperCase();
						textB = b.lastName.toUpperCase();
                        etatTriColumn = 3;
						break;
					case 3:
						textA = a.club.name.toUpperCase();
						textB = b.club.name.toUpperCase();
                        etatTriColumn = 4;
						break;
					case 4:
						textA = a.city.toUpperCase();
						textB = b.city.toUpperCase();
						etatTriColumn = 5;
						break;
					default:
						break;
				}

				if (etatTri)
				{
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
				}
				else
				{
					return (textA > textB) ? -1 : (textA > textB) ? 0 : 1;
				}
			});

			this.etatTriTableau = !etatTri;
			this.etatTriColumn = etatTriColumn;
		},
		
		// Gestion 
        getDateHourString(date) {
            // prints date & time in YYYY-MM-DD HH:MM:SS format
            return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + " " + date.getHours() + ":" + date.getMinutes() + ":" + ("0" + date.getSeconds()).slice(-2);
        },
		
		getDateString(date)
		{
			// prints date & time in YYYY-MM-DD format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
		},
		AjouterUtilisateurModal()
		{
			this.checkToken();

			this.checkAddUser = true;
			this.viewUser = false;
			this.verifMajUtilisateur = false;
			this.verifMajArbitre = false;

			this.$refs['modalUtilisateur'].show();
			this.nationalNumber = 0;
			this.permitNumber = "";
			this.firstName = "";
			this.lastName = "";
			this.birthday = "";
			this.club = "";
			this.address = "";
			this.email = "";
			this.phone = "";
			this.status = 0;
			this.distanceMax = 0;
			this.refereeLevel = 0;
			this.refereePosition = 1;
			this.canDouble = false;
			this.tutor = false;
			this.tutored = false;
			this.isDesignatable = true;
			this.colleagueMandatory = 0;
			this.optionalColleague = 0;
			// Rôles
			this.ArbitreDesignation = false;
			this.ArbitreRetour = false;
			this.Utilisateur = false;
			this.AdminUtilisateur = false;
			this.Repartiteur = false;
			this.AdminRepartiteur = false;
			this.Retour = false;
			this.AdminRetour = false;
			this.AdminEntite = false;

			this.accountActivation = "";
			this.lastConnection = "";

			this.groupRefereeMandatory = 0;
			this.groupRefereeOptionnel = 0;
			this.listRefereeMandatoryPos1 = [];
			this.listRefereeMandatoryPos2 = [];
			this.listRefereeOptionnelPos1 = [];
			this.listRefereeOptionnelPos2 = [];

			this.filterRefereeMandatory();
			this.filterRefereeOptionnel();
			this.apiModalMatch();
		},
		AjouterUtilisateur()
		{
			this.checkToken();
			this.$refs['modalUtilisateur'].hide();

			this.clubId = this.club.id;

			// Rôles
			let roles = [];
			if (this.ArbitreDesignation)
				roles.push("ArbitreDesignation");
			if (this.ArbitreRetour)
				roles.push("ArbitreRetour");
			if (this.Utilisateur)
				roles.push("Utilisateur");
			if (this.AdminUtilisateur)
				roles.push("AdminUtilisateur");
			if (this.Repartiteur)
				roles.push("Repartiteur");
			if (this.AdminRepartiteur)
				roles.push("AdminRepartiteur");
			if (this.Retour)
				roles.push("Retour");
			if (this.AdminRetour)
				roles.push("AdminRetour");
			if (this.AdminEntite)
				roles.push("AdminEntite");

			let json = {
				nationalNumber:parseInt(this.nationalNumber),
				permitNumber:this.permitNumber,
				firstName:this.firstName,
				lastName:this.lastName,
				birthday:this.getDateString(new Date(this.birthday)),
				club:this.clubId,
				address:this.address,
				email:this.email,
				phone:this.phone,
				status: this.status,
				distanceMax:parseInt(this.distanceMax),
				refereeLevel:parseInt(this.refereeLevel),
				refereePosition:parseInt(this.refereePosition),
				canDouble:this.canDouble,
				tutor:this.tutor,
				tutored:this.tutored,
				isDesignatable:this.isDesignatable,
				colleagueMandatory:parseInt(this.colleagueMandatory),
				optionalColleague:parseInt(this.optionalColleague),
				// Rôles
				role:roles
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				}
			};

			this.$axios.post('https://api.clickandball.fr/utilisateur', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Utilisateur ajouté");
						this.apiGetInfoIsUpdate = false;
                        this.chargementDeLaPage();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		InformationUtilisateurModal(info)
		{	
			this.checkToken();
			this.apiModalMatch();

			this.checkAddUser = false;
			this.verifMajArbitre = false;
			this.verifMajUtilisateur = false;
			this.viewUser = true;

			this.nationalNumber = parseInt(info.nationalNumber);
			this.permitNumber = info.permitNumber;
			this.firstName = info.firstName;
			this.lastName = info.lastName;
			this.birthday = this.getDateString(new Date(info.birthday));
			this.club = info.club.name;
			this.address = info.address + ", " + info.city;
			this.email = info.email;
			this.phone = info.phone;
			this.distanceMax = parseInt(info.distanceMax);
			this.refereeLevel = parseInt(info.refereeLevel.id);
			this.refereePosition = parseInt(info.refereePosition);
			this.canDouble = info.canDouble;
			this.tutor = info.tutor;
			this.tutored = info.tutored;
			this.status = info.status;
			this.isDesignatable = info.isDesignatable;
			this.colleagueMandatory = parseInt(info.colleagueMandatory.nationalNumber);
			this.optionalColleague = parseInt(info.optionalColleague.nationalNumber);

			// Rôles
			this.ArbitreDesignation = info.role.findIndex(x => x == "ArbitreDesignation") != -1;
			this.ArbitreRetour = info.role.findIndex(x => x == "ArbitreRetour") != -1;
			this.Utilisateur = info.role.findIndex(x => x == "Utilisateur") != -1;
			this.AdminUtilisateur = info.role.findIndex(x => x == "AdminUtilisateur") != -1;
			this.Repartiteur = info.role.findIndex(x => x == "Repartiteur") != -1;
			this.AdminRepartiteur = info.role.findIndex(x => x == "AdminRepartiteur") != -1;
			this.Retour = info.role.findIndex(x => x == "Retour") != -1;
			this.AdminRetour = info.role.findIndex(x => x == "AdminRetour") != -1;
			this.AdminEntite = info.role.findIndex(x => x == "AdminEntite") != -1;

			this.accountActivation = info.accountActivation;
			this.lastConnection = info.lastConnection;

			this.accountIsActivated = info.accountIsActivated;

			this.filterRefereeMandatory();
			this.filterRefereeOptionnel();
			this.$refs['modalUtilisateur'].show();
		},
		MajUtilisateurModal(info)
		{
			this.checkToken();

			this.checkAddUser = false;
			this.viewUser = false;
			if (this.roleAdminUtilisateur)
			{
				this.verifMajUtilisateur = true;
				this.verifMajArbitre = false;
			}
			else if (this.roleUtilisateur)
			{
				this.verifMajUtilisateur = false;
				this.verifMajArbitre = true;
			}

			this.apiModalMatch();
			this.nationalNumber = parseInt(info.nationalNumber);
			this.permitNumber = info.permitNumber;
			this.firstName = info.firstName;
			this.lastName = info.lastName;
			this.birthday = this.getDateString(new Date(info.birthday));
			this.club = info.club;
			this.clubId = info.club.id;
			this.address = info.address + ", " + info.city;
			this.nouvelleAddress = false;
			this.email = info.email;
			this.phone = info.phone;
			// Rôles
			this.ArbitreDesignation = info.role.findIndex(x => x == "ArbitreDesignation") != -1;
			this.ArbitreRetour = info.role.findIndex(x => x == "ArbitreRetour") != -1;
			this.Utilisateur = info.role.findIndex(x => x == "Utilisateur") != -1;
			this.AdminUtilisateur = info.role.findIndex(x => x == "AdminUtilisateur") != -1;
			this.Repartiteur = info.role.findIndex(x => x == "Repartiteur") != -1;
			this.AdminRepartiteur = info.role.findIndex(x => x == "AdminRepartiteur") != -1;
			this.Retour = info.role.findIndex(x => x == "Retour") != -1;
			this.AdminRetour = info.role.findIndex(x => x == "AdminRetour") != -1;
			this.AdminEntite = info.role.findIndex(x => x == "AdminEntite") != -1;

			this.accountActivation = info.accountActivation;
			this.lastConnection = info.lastConnection;
            this.accountIsActivated = info.accountIsActivated;

			if (this.isDesignatable)
			{
				this.distanceMax = parseInt(info.distanceMax);
				this.refereeLevel = parseInt(info.refereeLevel.id);
				this.refereePosition = parseInt(info.refereePosition);
				this.canDouble = info.canDouble;
				this.tutor = info.tutor;
				this.tutored = info.tutored;
				this.status = info.status
				this.isDesignatable = info.isDesignatable;
				this.groupRefereeMandatory = 0;
				this.colleagueMandatory = parseInt(info.colleagueMandatory.nationalNumber);
				this.groupRefereeOptionnel = 0;
				this.optionalColleague = parseInt(info.optionalColleague.nationalNumber);
			}
			else
			{
				this.distanceMax = 0;
				this.refereeLevel = 0;
				this.refereePosition = 0;
				this.canDouble = false;
				this.tutor = false;
				this.tutored = false;
				this.status = 0;
				this.isDesignatable = false;
				this.groupRefereeMandatory = 0;
				this.colleagueMandatory = 0;
				this.groupRefereeOptionnel = 0;
				this.optionalColleague = 0;
			}

			this.filterRefereeMandatory();
			this.filterRefereeOptionnel();
			this.$refs['modalUtilisateur'].show();
		},
		MajUtilisateur()
		{
			this.checkToken();
			this.$refs['modalUtilisateur'].hide();

			// Rôles
			let roles = [];
			if (this.ArbitreDesignation)
				roles.push("ArbitreDesignation");
			if (this.ArbitreRetour)
				roles.push("ArbitreRetour");
			if (this.Utilisateur)
				roles.push("Utilisateur");
			if (this.AdminUtilisateur)
				roles.push("AdminUtilisateur");
			if (this.Repartiteur)
				roles.push("Repartiteur");
			if (this.AdminRepartiteur)
				roles.push("AdminRepartiteur");
			if (this.Retour)
				roles.push("Retour");
			if (this.AdminRetour)
				roles.push("AdminRetour");
			if (this.AdminEntite)
				roles.push("AdminEntite");

			let json = {
				nationalNumber:parseInt(this.nationalNumber),
				club:this.club.id,
				address:this.address,
				nouvelleAddress:this.nouvelleAddress,
				email:this.email,
				phone:this.phone,
				// Désignation
				status: this.status,
				distanceMax:parseInt(this.distanceMax),
				refereeLevel:parseInt(this.refereeLevel),
				refereePosition:parseInt(this.refereePosition),
				canDouble:this.canDouble,
				tutor:this.tutor,
				tutored:this.tutored,
				isDesignatable:this.isDesignatable,
				colleagueMandatory:parseInt(this.colleagueMandatory),
				optionalColleague:parseInt(this.optionalColleague),
				// Rôles
				role:roles,
				accountIsActivated: this.accountIsActivated
			};

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				}
			};

			this.$axios.put('https://api.clickandball.fr/utilisateur', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Utilisateur mis à jour");
                        this.chargementDeLaPage();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		majArbitre()
		{
			this.checkToken();
			this.$refs['modalUtilisateur'].hide();

			let json = {
				nationalNumber:parseInt(this.nationalNumber),
				address:this.address,
				nouvelleAddress:this.nouvelleAddress,
				phone:this.phone,
				distanceMax:parseInt(this.distanceMax),
				refereeLevel:parseInt(this.refereeLevel),
				refereePosition:parseInt(this.refereePosition),
				canDouble:this.canDouble,
				tutor:this.tutor,
				tutored: this.tutored,
				status: this.status,
				colleagueMandatory:parseInt(this.colleagueMandatory),
				optionalColleague:parseInt(this.optionalColleague)
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				}
			};

			this.$axios.put('https://api.clickandball.fr/utilisateur/arbitre', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Arbitre mis à jour");
                        this.chargementDeLaPage();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		//Envoi d'un nouveau lien d'activation
		newActivation() {
			this.checkToken();
			this.$refs['modalUtilisateur'].hide();

            let json = {
                nationalNumber: parseInt(this.nationalNumber),
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email
            };

            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token,
                }
			};
            this.$axios.post('https://api.clickandball.fr/utilisateur/mailactivation', json, config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.showAlertSuccess("Nouveau lien d'activation envoyé");
                    }
                    else {
                        this.showAlertWarning('Erreur : ' + response.statusText);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
		downloadCSV()
		{
			this.checkToken();
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
					'Content-Disposition': 'attachment'
				},
				responseType: 'blob'
			};

			this.$axios.get('https://api.clickandball.fr/utilisateur/extraction', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', 'Utilisateurs.csv');
						document.body.appendChild(link);
						link.click();
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},

		apiModalMatch()
		{
			this.checkToken();

			if (!this.apiGetInfoIsUpdate)
			{
				this.apiGetInfoIsUpdate = true;
				this.listdivisionId = [];
				this.listClub = [];
				this.listGroup = [];

				this.listRefereeAvailable = [];
				this.listRefereeMandatoryPos1 = [];
				this.listRefereeMandatoryPos2 = [];
				this.listRefereeOptionnelPos1 = [];
				this.listRefereeOptionnelPos2 = [];

				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.get('https://api.clickandball.fr/division', config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.listdivisionId = response.data;
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error); 
					});
				
				this.$axios.get('https://api.clickandball.fr/groupe', config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.listGroup = response.data;
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error); 
					});

				this.$axios.get('https://api.clickandball.fr/club', config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.listClub = response.data;
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error); 
					});

				this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre', config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.listRefereeAvailable = response.data;
							
							this.listRefereeAvailable.forEach(x => {
								if (x.refereePosition === 1)
								{
									this.listRefereeMandatoryPos1.push(x);
									this.listRefereeOptionnelPos1.push(x);
								}
								else if (x.refereePosition === 2)
								{
									this.listRefereeMandatoryPos2.push(x);
									this.listRefereeOptionnelPos2.push(x);
								}
							})
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			}
		},
		searchAddressAPIEtalab()
		{
			this.nouvelleAddress = true;
			var value = this.address;

			if (value != "")
			{
				this.$axios.get('https://api-adresse.data.gouv.fr/search/?q=' + value + '&type=housenumber&autocomplete=1')
					.then(response => {
						if (response.status == 200)
						{
							if (response.data.features.length != 0)
							{
								this.listSuggestedAddress = [];
								for (var i = 0; i < response.data.features.length; i++)
								{
									this.listSuggestedAddress.push(response.data.features[i].properties.label);
								}
							}
						}
					})
			}
		},
		selectAddress(info)
		{
			this.address = info;
			this.listSuggestedAddress = [];
		},
		filterRefereeMandatory()
		{
			this.listRefereeMandatoryPos1 = [];
			this.listRefereeMandatoryPos2 = [];
			this.listRefereeAvailable.forEach(x => {
				if ((x.refereeLevel.id == this.groupRefereeMandatory && x.refereePosition == 1) || (0 == this.groupRefereeMandatory && x.refereePosition === 1))
				{
					this.listRefereeMandatoryPos1.push(x);
				}
				else if ((x.refereeLevel.id == this.groupRefereeMandatory && x.refereePosition === 2) || (0 == this.groupRefereeMandatory && x.refereePosition === 2))
				{
					this.listRefereeMandatoryPos2.push(x);
				}
			})
		},
		filterRefereeOptionnel()
		{
			this.listRefereeOptionnelPos1 = [];
			this.listRefereeOptionnelPos2 = [];
			this.listRefereeAvailable.forEach(x => {
				if ((x.refereeLevel.id == this.groupRefereeOptionnel && x.refereePosition === 1) || (0 == this.groupRefereeOptionnel && x.refereePosition === 1))
				{
					this.listRefereeOptionnelPos1.push(x);
				}
				else if ((x.refereeLevel.id == this.groupRefereeOptionnel && x.refereePosition === 2) || (0 == this.groupRefereeOptionnel && x.refereePosition === 2))
				{
					this.listRefereeOptionnelPos2.push(x);
				}
			})
		}
	}
}
</script>


